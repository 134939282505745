import React, { useState } from "react";
import "../scss/main.scss";
import "../styles/developer.scss";

import "../styles/latest-developer.scss";
import { useParams, Switch, Route, useRouteMatch } from "react-router-dom";

import Instructions from "../components/Instructions";
import { Unity, useUnityContext } from "react-unity-webgl";
import { config } from "../data";
import ConfiguratorFooter from "../components/ConfiguratorFooter";
import FinishConfigurationFooter from "../components/FinishConfigurationFooter";
import VehicleDataIntegrationFooter from "../components/VehicleDataIntegrationFooter";
import LeftDrawer from "../components/LeftDrawer";
import RightDrawer from "../components/RightDrawer";
import FinishConfiguration from "../components/FinishConfiguration";
import VehicleDataIntegration from "../components/VehicleDataIntegration";
import { useEffect } from "react";

export default function Configurator() {

  const [instructions, setInstructions] = useState(false);
  const [activeCam, setActiveCam] = useState(null);
  const [embossing, setEmbossing] = useState(false);
  const { lang, seriesId, productId } = useParams();

  const [enableAccordion, setEnableAccordion] = useState({ pointerEvents: "none" });
  const [contactFormData, setContactFormData] = useState({
    userName: { value: "", required: true },
    userLastName: { value: "", required: true },
    userMail: { value: "", required: true },
    userPhone: { value: "", required: true },
    vehicleBrand: { value: "", required: true },
    vehicleModel: { value: "", required: true },
    yearOfConstruction: { value: "", required: true },
    bodyColor: { value: "", required: false },
    noOfSeats: { value: "", required: false },
    note: { value: "", required: false },
  });
  let { path } = useRouteMatch();
  let match = useRouteMatch({
    path: "/:lang/:seriesId/:productId/config/vehicle-data-integration",
    exact: true
  });
  let configMatch = useRouteMatch({
    path: "/:lang/:seriesId/:productId/config",
    exact: true
  });

  const {
    unityProvider,
    loadingProgression,
    sendMessage, 
    isLoaded,
    takeScreenshot,
    unload,
    UNSAFE__unityInstance: unityInstance,
    UNSAFE__detachAndUnloadImmediate: detachAndUnloadImmediate,
    updateMaterials, 
    requestFullscreen
  } = useUnityContext({ ...config.series[seriesId].products[productId].buildObject });

  const [initialized, setInitialized] = useState(false);
  const [timer, setTimer] = useState(false);
  const [timerPercent, setTimerPercent] = useState(0.9);

  const [recap, setRecap] = useState(false);

  const getTimerInterval = (seriesId) => {
    if (seriesId === "ab") {
      return 20
    }
    return 100
  }

  useEffect(() => {
    //Implementing the setInterval method 
    const intervalDuration = getTimerInterval(seriesId)
    if (loadingProgression >= 0.9) {
      setTimer(true)
      const interval = setInterval(() => {
        setTimerPercent((timerPercent + 0.0001))
      }, intervalDuration);

      //Clearing the interval 
      if (timerPercent >= 0.9998) {
        clearInterval(interval)
      }
      if (isLoaded) {
        clearInterval(interval)
      }
      return () => clearInterval(interval);
    }
  }, [timerPercent, loadingProgression, isLoaded]);

  const [history, setHistory] = useState(() => {
    const storedState = localStorage.getItem('history');
    return storedState ? JSON.parse(storedState) : {};
  });

  const [materialHistory, setMaterialHistory] = useState(() => {
    const storedState = localStorage.getItem('materialHistory');
    return storedState ? JSON.parse(storedState) : {};
  });

  const [catHistory, setCatHistory] = useState(() => {
    const storedState = localStorage.getItem('catHistory');
    return storedState ? JSON.parse(storedState) : { ...config.series[seriesId].products[productId].catHistoryMap };
  });

  useEffect(() => {
    localStorage.setItem('history', JSON.stringify(history));
  }, [history]);

  useEffect(() => {
    localStorage.setItem('materialHistory', JSON.stringify(materialHistory));
  }, [materialHistory]);

  useEffect(() => {
    localStorage.setItem('catHistory', JSON.stringify(catHistory));
  }, [catHistory]);

  useEffect(() => {
    return () => {
      localStorage.clear();
    }
  }, []);

  useEffect(() => {
    return () => {
      try {
        detachAndUnloadImmediate().catch((reason) => {
          console.log(reason)
        })
      } catch (e) {
        console.log(e)
      }
    }
  }, [detachAndUnloadImmediate]);



  useEffect(() => {
    for (const part in materialHistory) {
      sendMessage("Main Camera", "UpdateMaterial", JSON.stringify(materialHistory[part]));
    }
  }, [isLoaded]);

  const handleFullScreenClick = () => {
    requestFullscreen(true);
  }

  const handleCameraChange = (seriesId, camName) => {
    if (config.series) {
      let camPositions = config.series[seriesId].products[productId].onScreenCameraPositions;
      if (camPositions) {
        camPositions.map((item) => {
          if (item.type === camName) {
            sendMessage("Main Camera", "UpdateCamera", JSON.stringify(item));
          }
        })
        console.log("camPositions:", camPositions);
      }
    }
  }

  const prepareMaterialNameForCentraleSedile = (m, info) => {
    if (!info) {
      return;
    }
    let tmp1 = "", tmp2 = "", tmp3 = "", tmp4 = "", color = "";
    switch (info.slug) {
      case "alcantara":
      case "nappa":
      case "stampa":
        tmp1 = "centrale_sedile";
        if (history.centrale_design && config.materials[history.centrale_design.selectedMat].slug) {
          tmp1 = "centrale_design";
          tmp2 = "_" + config.materials[history.centrale_design.selectedMat].slug
        }
        tmp3 = "_" + info.slug
        if (history.centrale_traforature && config.materials[history.centrale_traforature.selectedMat].slug) {
          tmp4 = "_" + config.materials[history.centrale_traforature.selectedMat].slug
        }
        color = config.materials[m].color;
        break;
      case "forato":
        tmp1 = "centrale_sedile"
        if (history.centrale_design && config.materials[history.centrale_design.selectedMat].slug) {
          tmp1 = "centrale_design";
          tmp2 = "_" + config.materials[history.centrale_design.selectedMat].slug
        }
        if (history.centrale_sedile && history.centrale_sedile.slug) {
          tmp3 = "_" + history.centrale_sedile.slug
        } else {
          tmp3 = "_alcantara"
        }
        tmp4 = config.materials[m].slug ? "_" + config.materials[m].slug : "";
        color = history.centrale_sedile && history.centrale_sedile.selectedMat ? config.materials[history.centrale_sedile.selectedMat].color : "808080";
        break;
      case "design":
        tmp1 = config.materials[m].slug ? "centrale_design" : "centrale_sedile"
        tmp2 = config.materials[m].slug ? "_" + config.materials[m].slug : ""
        if (history.centrale_sedile && history.centrale_sedile.slug) {
          tmp3 = "_" + history.centrale_sedile.slug
        } else {
          tmp3 = "_alcantara"
        }
        if (history.centrale_traforature && config.materials[history.centrale_traforature.selectedMat].slug) {
          tmp4 = "_" + config.materials[history.centrale_traforature.selectedMat].slug
        }
        color = history.centrale_sedile && history.centrale_sedile.selectedMat ? config.materials[history.centrale_sedile.selectedMat].color : "808080";
        break;
      default:
        break;
    }
    let data = { Part: tmp1 + tmp2 + tmp3 + tmp4, Color: color };
    if (history.filati_design) {
      data.Cuciture_Color = config.materials[history.filati_design.selectedMat].color
    }
    return data
  }

  const handleClick = (m, info) => {
    const historyTmp = { ...history };
    let materialHistoryTmp = { ...materialHistory };
    const { materials, ...rest } = info
    if (info.slug === "filati_design" && !historyTmp.centrale_design) {
      return
    }
    historyTmp[info.historySlug] = {
      selectedMat: m,
      ...rest
    };

    if (info.material) {
      sendMessage("Main Camera", "UpdateMaterial", JSON.stringify({ Part: info.material, Color: config.materials[m].color }));
      materialHistoryTmp[info.historySlug] = { Part: info.material, Color: config.materials[m].color };
    }
    if (info.type === "embossed") {
      setEmbossing(true);
      if (historyTmp.centrale_design) {
        delete historyTmp.centrale_design
        if (historyTmp.filati_design) {
          delete historyTmp.filati_design
          if (materialHistoryTmp.centrale_sedile) {
            sendMessage("Main Camera", "UpdateMaterial", JSON.stringify({ Part: materialHistoryTmp["centrale_sedile"].Part, Color: materialHistoryTmp["centrale_sedile"].Color, Cuciture_Color: "808080" }));
          }
        }
      }
      if (historyTmp.centrale_traforature) {
        delete historyTmp.centrale_traforature
      }

      if (m === "no_embossing" || m === "no_embossing_super") {
        if (historyTmp.centrale_sedile) {
          delete historyTmp.centrale_sedile
        }
        if (historyTmp.centrale_embossing) {
          delete historyTmp.centrale_embossing
        }
        sendMessage("Main Camera", "UpdateMaterial", JSON.stringify({ Part: `centrale_sedile_alcantara`, Color: "808080" }));
        materialHistoryTmp["centrale_sedile"] = { Part: `centrale_sedile_alcantara`, Color: "808080" };
      } else {
        let color = null;
        if (historyTmp.centrale_sedile && !config.series[seriesId].products[productId].allowedMatForEmbossing.includes(historyTmp.centrale_sedile.selectedMat)) {
          delete historyTmp.centrale_sedile
          color = "808080";
        } else if (historyTmp.centrale_sedile) {
          color = config.materials[historyTmp.centrale_sedile.selectedMat].color;
        }
        sendMessage("Main Camera", "UpdateMaterial", JSON.stringify({ Part: `centrale_embossing_${config.materials[m].slug}`, Color: color }));
        materialHistoryTmp["centrale_sedile"] = { Part: `centrale_embossing_${config.materials[m].slug}`, Color: color };
      }
    }
    if (info.type === "filati" && info.slug !== "filati_design" &&
      m !== "no_filati_sedile" && m !== "no_filati_volante" && m !== "no_filati_parti_rigide" &&
      m !== "no_filati_bracciolo_centrale" && m !== "no_filati_tunnel_centrale" && m !== "no_filati_cruscotto") {
      sendMessage("Main Camera", "UpdateMaterial", JSON.stringify({ Part: info.onMaterial, Color: config.materials[m].color }));
      materialHistoryTmp[info.historySlug] = { Part: info.onMaterial, Color: config.materials[m].color };
    }
    if (info.type === "filati" && info.slug !== "filati_design" &&
      (m === "no_filati_sedile" || m === "no_filati_volante" || m === "no_filati_parti_rigide" ||
        m === "no_filati_bracciolo_centrale" || m === "no_filati_tunnel_centrale" || m === "no_filati_cruscotto")) {
      sendMessage("Main Camera", "UpdateMaterial", JSON.stringify({ Part: info.offMaterial, Color: config.materials[m].color }));
      materialHistoryTmp[info.historySlug] = { Part: info.offMaterial, Color: config.materials[m].color };
    }
    if (info.historySlug === "centrale_sedile") {
      if (info.slug === "alcantara" || info.slug === "stampa") {
        setEmbossing(false);
        if (historyTmp.centrale_embossing) {
          delete historyTmp.centrale_embossing
        }
        const parseData = prepareMaterialNameForCentraleSedile(m, info)
        sendMessage("Main Camera", "UpdateMaterial", JSON.stringify(parseData));
        materialHistoryTmp["centrale_sedile"] = parseData
      }
      if (info.slug === "nappa") {
        if (embossing) {
          sendMessage("Main Camera", "UpdateMaterial", JSON.stringify({ Part: materialHistoryTmp.centrale_sedile.Part, Color: config.materials[m].color }));
          materialHistoryTmp["centrale_sedile"] = { Part: materialHistoryTmp.centrale_sedile.Part, Color: config.materials[m].color }
        } else {
          if (historyTmp.centrale_embossing) {
            delete historyTmp.centrale_embossing
          }
          const parseData = prepareMaterialNameForCentraleSedile(m, info)
          sendMessage("Main Camera", "UpdateMaterial", JSON.stringify(parseData));
          materialHistoryTmp["centrale_sedile"] = parseData
        }
      }
    }
    if (info.historySlug === "centrale_traforature" || info.historySlug === "centrale_design") {

      if (m === "no_design" && historyTmp.centrale_design) {
        delete historyTmp.centrale_design
        if (historyTmp.filati_design) {
          delete historyTmp.filati_design
          if (materialHistoryTmp.centrale_sedile) {
            sendMessage("Main Camera", "UpdateMaterial", JSON.stringify({ Part: materialHistoryTmp["centrale_sedile"].Part, Color: materialHistoryTmp["centrale_sedile"].Color, Cuciture_Color: "808080" }));
          }
        }
      }
      if (m === "no_perf" && historyTmp.centrale_traforature) {
        delete historyTmp.centrale_traforature
      }
      setEmbossing(false);
      if (historyTmp.centrale_embossing) {
        delete historyTmp.centrale_embossing
      }
      const parseData = prepareMaterialNameForCentraleSedile(m, info)
      sendMessage("Main Camera", "UpdateMaterial", JSON.stringify(parseData));
      materialHistoryTmp["centrale_sedile"] = parseData
    }

    if (info.slug === "filati_design") {
      if (m === "no_filati_design") {
        if (historyTmp.filati_design) {
          delete historyTmp.filati_design
          if (materialHistoryTmp.centrale_sedile) {
            sendMessage("Main Camera", "UpdateMaterial", JSON.stringify({ Part: materialHistoryTmp["centrale_sedile"].Part, Color: materialHistoryTmp["centrale_sedile"].Color, Cuciture_Color: "808080" }));
          }
        }
      } else {
        sendMessage("Main Camera", "UpdateMaterial", JSON.stringify({ Part: materialHistoryTmp["centrale_sedile"].Part, Color: materialHistoryTmp["centrale_sedile"].Color, Cuciture_Color: config.materials[m].color }));
        materialHistoryTmp["centrale_sedile"] = { Part: materialHistoryTmp["centrale_sedile"].Part, Color: materialHistoryTmp["centrale_sedile"].Color, Cuciture_Color: config.materials[m].color }
      }
    }
    const catTmp = {}
    catTmp[info.catHistorySlug] = true;
    setCatHistory({ ...catHistory, ...catTmp })
    setHistory({ ...historyTmp })
    setMaterialHistory({ ...materialHistoryTmp })
  }

  const onInitialize = () => {
    var button = document.getElementById("myCollapse").getElementsByClassName("accordion-button")[0];
    if (button) button.click();
    setEnableAccordion({ pointerEvents: "auto" })
  };

  return (
    <>
      {instructions && <Instructions onClose={() => { setInstructions(false) }} />}
      <main style={{ opacity: instructions ? '0' : '1', position: instructions ? "absolute" : "relative" }}  >
        <section className={"customization" + (match ? " contact" : "")}>
          <Switch>
            <Route exact path={path}>
              <button className="btn btn-primary offcanvasButton" onClick={() => {
                document.getElementById("offcanvaSidebar").style.width = "350px";
                document.getElementById("applicaWrapper").style.position = "fixed";
              }}> MENU SCELTE</button>
              <LeftDrawer handleClick={handleClick} enableAccordion={enableAccordion} />
            </Route>
          </Switch>
          <div className="configurator">
            <div className="symbols top-right">
              <img
                style={enableAccordion}
                onClick={() => {
                  setActiveCam("cam_01");
                  handleCameraChange(seriesId, 'cam_01')
                }}
                alt=""
                className={`camImg cam-1`}
                src={`${activeCam === "cam_01" ? "/images/cam-icons/icone camere ms blu-01.svg" : "/images/cam-icons/icone camere ms-01.svg"}`}
              />
              <img
                style={enableAccordion}
                onClick={() => {
                  setActiveCam("cam_02");
                  handleCameraChange(seriesId, 'cam_02')
                }
                }
                alt=""
                className={`camImg cam-2`}
                src={`${activeCam === "cam_02" ? "/images/cam-icons/icone camere ms blu-02.svg" : "/images/cam-icons/icone camere ms-02.svg"}`}
              />
              <img
                style={enableAccordion}
                onClick={() => {
                  setActiveCam("cam_03");
                  handleCameraChange(seriesId, 'cam_03')
                }}
                alt=""
                className={`camImg cam-3`}
                src={`${activeCam === "cam_03" ? "/images/cam-icons/icone camere ms blu-03.svg" : "/images/cam-icons/icone camere ms-03.svg"}`}
              />
              <img
                style={enableAccordion}
                onClick={() => {
                  setActiveCam("cam_04");
                  handleCameraChange(seriesId, 'cam_04')
                }}
                alt=""
                className={`camImg cam-4`}
                src={`${activeCam === "cam_04" ? "/images/cam-icons/icone camere ms blu-04.svg" : "/images/cam-icons/icone camere ms-04.svg"}`}
              />
              <img
                style={enableAccordion}
                onClick={() => {
                  handleFullScreenClick()
                }}
                alt=""
                className={"fullScreenImg"} src="/images/full_screen_icon.png" />
            </div>
            {!isLoaded ? (
              <p className={`d-flex h-100 align-items-center justify-content-center w-100 text-white `}>
                Loading Application... {timer ? parseFloat((timerPercent * 100)).toFixed(2) : loadingProgression ? parseFloat(loadingProgression * 100).toFixed(2) : 0}%
              </p>
            ) : configMatch && !initialized ? (
              <span
                href="#"
                className="start-configuration custom-clickable-link"
                onClick={() => {
                  onInitialize();
                  setInitialized(true);
                }}
              >
                <h4>INIZIA CONFIGURAZIONE</h4>
              </span>
            ) : (
              <></>
            )}

            <Unity
              tabIndex={1}
              unityProvider={unityProvider}
              style={{ width: "100%", maxWidth: "100%", display: isLoaded ? "block" : "none" }}
              onError={(error) => {
                console.error("Errore durante il caricamento o l'esecuzione dell'applicazione Unity:", error);
                // Puoi eseguire azioni di ripristino qui, come mostrare un messaggio di errore all'utente
             
              }}
            />
            <div className="symbols">
              <img src="/images/rotate-image.png" />
              <img src="/images/logo-symbol.png" />
            </div>
          </div>
          <Switch>
            <Route exact path={path}>
              <div className="drawer">
                <div className="panel">
                  <h5>Selezioni Effettuate</h5>
                  <RightDrawer history={history} />
                </div>
              </div>
            </Route>
            <Route path={`${path}/finish-configuration`}>
              <FinishConfiguration catHistory={catHistory} />
            </Route>
            <Route path={`${path}/vehicle-data-integration`}>
              <VehicleDataIntegration recap={recap} setRecap={setRecap} history={history} catHistory={catHistory} materialHistory={materialHistory}
                contactFormData={contactFormData} setContactFormData={setContactFormData}
              />
            </Route>
          </Switch>
        </section>
      </main >

      <Switch>
        <Route exact path={path}>
          <ConfiguratorFooter instructions={instructions} setInstructions={setInstructions} />
        </Route>
        <Route path={`${path}/finish-configuration`}>
          <FinishConfigurationFooter catHistory={catHistory} />
        </Route>
        <Route path={`${path}/vehicle-data-integration`}>
          <VehicleDataIntegrationFooter recap={recap} setRecap={setRecap} />
        </Route>
      </Switch>
    </>
  );
}
