import { config } from "../data";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { useParams } from "react-router-dom";


function MaterialImage({ sName, sCategory }) {
    const popover = (
        <Popover id={`popover-basic${sCategory}-${sName}`} className="popover">
            <Popover.Body>
                <img src={`/images/${sCategory}/${sName}`} className={`hover-thumb-img ${sCategory}`} />
            </Popover.Body>
        </Popover>
    );

    return (
        <OverlayTrigger trigger={["hover", "focus"]} placement="left" overlay={popover}>
            <div className="border border-secondary img-thumb1" >
                <img src={`/images/${sCategory}/${sName}`} className={`history-${sCategory} img-fluid`} />
            </div>
        </OverlayTrigger>
    );

}

export default function RightDrawer({ history }) {
    const { lang, seriesId, productId } = useParams();
    return config.series[seriesId].products[productId].historyArray.map((h, hI) => {
        return history[h] ? (<table className="table" key={hI}>
            <thead className="table-dark">
                <tr>
                    <th colSpan="2" className="text-uppercase">
                        {history[h].history}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    {history[h].type === "color" ? (
                        <>
                            <td width="50%">
                                <div className="entry-title">{history[h].name}</div>
                                <div className="entry-selection">{config.materials[history[h].selectedMat].name}{config.materials[history[h].selectedMat].code ? "-" + (config.materials[history[h].selectedMat].code) : ""}</div>
                            </td>
                            <td width="50%">
                                <div className={`color-${config.materials[history[h].selectedMat].color} d-block `} style={{ backgroundColor: "#" + config.materials[history[h].selectedMat].color }}></div>
                            </td>
                        </>
                    ) : <></>}
                    {history[h].type === "filati" || history[h].type === "design" || history[h].type === "traforature" || history[h].type === "embossed" ? (
                        <>
                            <td width="50%">
                                <div className="entry-title">{history[h].name}</div>
                                <div className="entry-selection">{config.materials[history[h].selectedMat].name}{config.materials[history[h].selectedMat].code ? "-" + (config.materials[history[h].selectedMat].code) : ""}</div>
                            </td>
                            <td width="50%">
                                <MaterialImage sCategory={history[h].type} sName={config.materials[history[h].selectedMat].file} />
                            </td>
                        </>
                    ) : <></>}
                </tr>
            </tbody>
        </table>) : <></>
    })
}