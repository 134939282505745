import { useParams } from "react-router-dom";
import RightDrawer from "./RightDrawer";
import Modal from 'react-bootstrap/Modal';
import { config } from "../data";
import axios from "axios";
import { useState } from "react";

export default function VehicleDataIntegration({ recap, setRecap, history, catHistory, materialHistory, contactFormData, setContactFormData }) {

    const {seriesId, productId } = useParams();
    const [smShow, setSmShow] = useState(false)

    const updateFieldValue = (name, value) => {
        console.log("updateFieldValue:", name, value);
        let tempObj = {};
        tempObj[name] = { value: value, required: contactFormData[name].required };
        setContactFormData({ ...contactFormData, ...tempObj });
    };

    const sendMail = () => {
        let emailData = { history: history, catHistory: catHistory, materialHistory: materialHistory, contactFormData: contactFormData, seriesId: seriesId, productId: productId };
        axios
            .post(process.env.REACT_APP_API_URL, emailData)
            .then((response) => {
                if (response.status !== 200) {
                    throw response;
                }
            })
            .then(
                (result) => {
                    console.log("Success result", result);
                },
                (error) => {
                    console.error("Invalid request! Please try again.", error);
                }
            )
            .catch((error) => {
                console.error("Invalid request! Please try again.");
            });
    };

    const onSubmitFn = (e) => {
        e.preventDefault();
        setSmShow(true);
        setTimeout(() => {
            setSmShow(false);
            setRecap(true);
            sendMail();
        }, 2000);
    };

    return (
        <>
            {!recap ? <div className="contact-section">
                <h2>DATI DEL VEICOLO INTERESSATO</h2>
                <div className="spacer-lg-3"></div>
                <Modal
                    centered="true"
                    size="sm"
                    show={smShow}
                    onHide={() => {
                        setSmShow(false);
                    }}
                    aria-labelledby="example-modal-sizes-title-sm"
                >
                    <Modal.Body className="text-center">
                        <img src="/images/checked.png" width="50" height="50" alt=""/> <br />
                        RICHIESTA INVIATA <br />
                    </Modal.Body>
                </Modal>
                <form type="post" onSubmit={(e) => onSubmitFn(e)}>
                    <div className="contact-form border border-secondary bg-400">
                        <div className="mb-3">
                            <input
                                type="text"
                                className="form-control"
                                id="Username"
                                name="userName"
                                aria-describedby="Username"
                                placeholder="Inserire Nome utente*"
                                required={contactFormData.userName["required"]}
                                value={contactFormData.userName["value"]}
                                onChange={(e) => updateFieldValue(e.target.name, e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <input
                                type="text"
                                className="form-control"
                                id="userLastName"
                                name="userLastName"
                                aria-describedby="userLastName"
                                placeholder="Inserire Cognome Utente*"
                                required={contactFormData.userLastName["required"]}
                                value={contactFormData.userLastName["value"]}
                                onChange={(e) => updateFieldValue(e.target.name, e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <input
                                type="email"
                                className="form-control"
                                id="userMail"
                                name="userMail"
                                aria-describedby="userMail"
                                placeholder="Inserire Mail Utente*"
                                required={contactFormData.userMail["required"]}
                                value={contactFormData.userMail["value"]}
                                onChange={(e) => updateFieldValue(e.target.name, e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <input
                                type="number"
                                className="form-control"
                                id="userPhone"
                                name="userPhone"
                                aria-describedby="userPhone"
                                placeholder="Inserire Telefono Utente*"
                                required={contactFormData.userPhone["required"]}
                                value={contactFormData.userPhone["value"]}
                                onChange={(e) => updateFieldValue(e.target.name, e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="spacer-lg-1"></div>
                    <div className="contact-form border border-secondary bg-400">
                        <div className="mb-3">
                            <input
                                type="text"
                                className="form-control"
                                id="vehicleBrand"
                                name="vehicleBrand"
                                aria-describedby="vehicleBrand"
                                placeholder="Inserire Marca Veicolo*"
                                required={contactFormData.vehicleBrand["required"]}
                                value={contactFormData.vehicleBrand["value"]}
                                onChange={(e) => updateFieldValue(e.target.name, e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <input
                                type="text"
                                className="form-control"
                                id="vehicleModel"
                                name="vehicleModel"
                                aria-describedby="vehicleModel"
                                placeholder="Inserire Modello Veicolo per Esteso (es: Audi A5 - Sportback)*"
                                required={contactFormData.vehicleModel["required"]}
                                value={contactFormData.vehicleModel["value"]}
                                onChange={(e) => updateFieldValue(e.target.name, e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <input
                                type="text"
                                className="form-control"
                                id="yearOfConstruction"
                                name="yearOfConstruction"
                                aria-describedby="yearOfConstruction"
                                placeholder="Inserire Anno di Costruzione*"
                                required={contactFormData.yearOfConstruction["required"]}
                                value={contactFormData.yearOfConstruction["value"]}
                                onChange={(e) => updateFieldValue(e.target.name, e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <input
                                type="number"
                                className="form-control"
                                id="noOfSeats"
                                name="noOfSeats"
                                aria-describedby="noOfSeats"
                                placeholder="Inserire Numero Posti Vettura"
                                value={contactFormData.noOfSeats["value"]}
                                onChange={(e) => updateFieldValue(e.target.name, e.target.value)}
                            />
                        </div>
                        <textarea
                            className="form-control"
                            name="note"
                            id="note"
                            rows="4"
                            placeholder="Note"
                            onChange={(e) => updateFieldValue(e.target.name, e.target.value)}
                            value={contactFormData.note["value"]}
                        ></textarea>
                        <div className="form-text">(*) Campi obbligatori</div>
                    </div>
                    <div className="spacer-lg-3"></div>
                    <div className="generate-pdf">
                        <p>Il sistema genera un pdf con immagini in alta risoluzione e invierà automaticamente la richiesta a Medici Style Atelier</p>
                        <button className="btn btn-primary text-uppercase fs-sm-16 ms-3" type="submit">
                            GENERA RICHIESTA DI PREVENTIVO E PDF
                        </button>
                    </div>
                </form>
            </div> : <div className="drawer">
                <div className="panel pe-3 me-3 border-end border-secondary">
                    <>
                        <h5>Distinta Personalizzaizioni incluse nel Progetto</h5>
                        <table className="table">
                            <thead className="table-dark">
                                <tr>
                                    <th className="text-uppercase">Dati Utente</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td width="100%">
                                        <div className="entry-title">{contactFormData ? contactFormData.userName.value : ""}</div>
                                        <div className="entry-selection">{contactFormData ? contactFormData.userLastName.value : ""}</div>
                                        <div className="entry-selection">{contactFormData ? contactFormData.userMail.value : ""}</div>
                                        <div className="entry-selection">{contactFormData ? contactFormData.userPhone.value : ""}</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="table">
                            <thead className="table-dark">
                                <tr>
                                    <th className="text-uppercase">Dati Veicolo</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td width="100%">
                                        <div className="entry-title">{contactFormData ? contactFormData.vehicleBrand.value : ""}</div>
                                        <div className="entry-selection">{contactFormData ? contactFormData.vehicleModel.value : ""}</div>
                                        <div className="entry-selection">{contactFormData ? contactFormData.yearOfConstruction.value : ""}</div>
                                        <div className="entry-selection">{contactFormData ? contactFormData.noOfSeats.value : ""}</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <table className="table">
                            <thead className="table-dark">
                                <tr>
                                    <th colSpan="2" className="text-uppercase">
                                        Lavorazioni Opzionali
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="entry-button">
                                    <td width="50%">
                                        {config.series[seriesId].products[productId].catHistoryArray.map((catH, catHIndex) => {
                                            return <span key={catHIndex} className={`btn text-uppercase rounded-0 mx-1 my-1 ${catHistory[catH.code] ? "btn-primary" : "btn-outline-secondary "}`}>{catH.name}</span>
                                        })}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="table">
                            <thead className="table-dark">
                                <tr>
                                    <th className="text-uppercase">Note</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td width="100%">
                                        <p className="fst-italic text-600 ps-3">{contactFormData ? contactFormData.note.value : ""}</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </>
                </div>

                <div className="panel"><RightDrawer history={history} /></div>
            </div>}



        </>
    )
}