import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import { config } from "../data";

export default function EmailTemplateHTML(props) {
  const [history, setHistory] = useState({});
  const [catHistory, setCatHistory] = useState({});
  const [materials, setMaterials] = useState([]);
  const [applyMat, setApplyMat] = useState(false);
  const [contactFormData, setContactFormData] = useState({});
  const [pdfTemplateLoaded, setPdfTemplateLoaded] = useState(false);
  const { seriesId, productId } = useParams();

  const series = seriesId ? config.series[seriesId] : null;
  const product = productId ? series.products[productId] : null;
  const cameraPositions = product ? product["cameraPositions"] : null;
  const { unityProvider, loadingProgression, sendMessage, isLoaded, takeScreenshot, addEventListener, removeEventListener } = useUnityContext(
    product.buildObject
  );
  let images = [];

  const _cameraUpdatedCallback = async (response) => {

    
    let objResponse = JSON.parse(response);
    if (objResponse.imageId) {
      let dataUrl = await takeScreenshot();
      images[objResponse.imageId] = dataUrl;
    }
    if (objResponse.isLastImage) {
      _renderImages(images);
      setTimeout(() => {
        setPdfTemplateLoaded(true);
      }, 500);
    }
  };

  const _renderImages = (images) => {

    images &&
      Object.keys(images).map((index) => {
        let imgElem = document.getElementById(`${index}`);
        if (imgElem) {
          imgElem.src = images[index];
        }
      });

  };

  const generateImages = async () => {
    cameraPositions &&
      cameraPositions.map((value, index) => {
        setTimeout(() => {
          imageFetch(value, index, cameraPositions.length === index + 1);
        }, 500);
      });
  };

  const imageFetch = async (_value, index, isLastImage) => {
    await sendMessage("Main Camera", "UpdateCamera", JSON.stringify({ ..._value, imageId: `image${index + 1}`, isLastImage: isLastImage }));
  };

  const _multipleMaterialsUpdatedCallback = (color) => {
    generateImages();
  };


  useEffect(() => {
    if (applyMat) {
      sendMessage("Main Camera", "UpdateMaterials", JSON.stringify({ Materials: materials }));
    }
  }, [applyMat])

  

  useEffect(() => {

    addEventListener("MultipleMaterialsUpdated", _multipleMaterialsUpdatedCallback);
    addEventListener("CameraUpdated", _cameraUpdatedCallback);

    if (isLoaded) {
      
      
      let stateData = JSON.parse(window.localStorage.getItem(`${seriesId}${productId}`));

      if (stateData) {
        const { history, catHistory, materialHistory, contactFormData } = stateData;
        setHistory(history);
        setCatHistory(catHistory);
        setContactFormData(contactFormData);

        const tmpMat = [];
        config.series[seriesId].products[productId].materialHistoryArray.map((h, hIndex) => {
          if (materialHistory[h]) {
            tmpMat.push(materialHistory[h])
          }
        })
        setMaterials(tmpMat);
      }
      setApplyMat(true);
    }
    return () => {
      removeEventListener("MultipleMaterialsUpdated", _multipleMaterialsUpdatedCallback);
      removeEventListener("CameraUpdated", _cameraUpdatedCallback);
    };
  }, [isLoaded]);

  return (
    <>
      <page size="A4">
        <div className="page-container">
          {pdfTemplateLoaded ? <span id="pdfTemplateLoaded"></span> : <></>}
          <table>
            <thead>
              <tr>
                <td style={{ verticalAlign: "top", paddingBottom: "15px" }}>
                  <div className="header">
                    <div className="email-logo">
                      <img src="/images/logo-medici-atelier-dark.svg" />
                    </div>
                  </div>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ height: "670px", verticalAlign: "bottom" }}>
                  <div style={{ height: "100%", width: "100%", backgroundColor: "#000", position: "relative" }}>
                    {!isLoaded && (
                      <p className={`d-flex h-100 align-items-center justify-content-center w-100 text-white `}>
                        Loading Application... {loadingProgression ? Math.round(loadingProgression * 100) : 0}%
                      </p>
                    )}
                    <Unity
                      unityProvider={unityProvider}
                      style={{ height: "100%", maxWidth: "100%", display: isLoaded ? "block" : "none" }}
                      onError={(error) => {
                        console.log(error);
                      }}
                    />
                    <table style={{ tableLayout: "fixed", width: "100%", position: "absolute", bottom: "20px" }}>
                      <tr>
                        <td style={{ fontSize: "8px", verticalAlign: "bottom", paddingRight: "15px", paddingLeft: "15px", color: "#fff" }}>
                          La personalizzazione che avete impostato nel Configuratore Madici Style Atelier si basano su un modello generico di abitacolo, chiaramente le specifiche
                          realizzazioni potranno avere differenze rispetto al modello elaorato.
                        </td>
                        <td
                          style={{
                            backgroundColor: "#090a0a",
                            verticalAlign: "middle",
                            padding: "15px 30px",
                            backgroundImage: `url('/images/header-left-vector.png')`,
                            backgroundSize: "100% 100%",
                            width: "50%",
                            marginLeft: "15px",
                          }}
                        >
                          <p style={{ color: "#fff", marginTop: "0px", marginBottom: "10px" }}>Utente</p>
                          <p style={{ color: "#fff", marginTop: "0px", marginBottom: "10px" }}>{contactFormData.userName && contactFormData.userName.value} {contactFormData.userLastName && contactFormData.userLastName.value}</p>
                        </td>
                      </tr>
                    </table>
                  </div>

                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </page>

      <div className="pagebreak"> </div>
      <page size="A4">
        <div className="page-container">
          <table>
            <tbody>
              <tr>
                <td style={{ verticalAlign: "top", paddingBottom: "15px" }}>
                  <table style={{ width: "100%" }}>
                    <tr>
                      <td>
                        <div className="header">
                          <div className="email-logo">
                            <img src="/images/logo-medici-atelier-dark.svg" />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table style={{ width: "100%", tableLayout: "fixed" }}>
                    <tr>
                      <td style={{ verticalAlign: "top", paddingBottom: "5px" }}>
                        <ul style={{ listStyle: "none", paddingLeft: "0px", margin: "0px" }}>
                          <li style={{ backgroundColor: "#090A0A", color: "#fff", marginBottom: "5px", padding: "4px 10px", fontSize: "14px" }}>DATI UTENTE</li>
                          <li style={{ backgroundColor: "#878787", color: "#fff", marginBottom: "5px", padding: "2px 10px", fontSize: "12px" }}>NOME UTENTE: {contactFormData.userName && contactFormData.userName.value}</li>
                          <li style={{ backgroundColor: "#878787", color: "#fff", marginBottom: "5px", padding: "2px 10px", fontSize: "12px" }}>COGNOME UTENTE : {contactFormData.userLastName && contactFormData.userLastName.value}</li>
                          <li style={{ backgroundColor: "#878787", color: "#fff", marginBottom: "5px", padding: "2px 10px", fontSize: "12px" }}>EMAIL DI RIFERIMENTO: {contactFormData.userMail && contactFormData.userMail.value}</li>
                          <li style={{ backgroundColor: "#878787", color: "#fff", marginBottom: "5px", padding: "2px 10px", fontSize: "12px" }}>TEL./CELL.: {contactFormData.userPhone && contactFormData.userPhone.value}</li>
                        </ul>
                      </td>
                      <td style={{ verticalAlign: "top" }}>
                        <table style={{ width: "100%" }}>
                          <tr>
                            <td style={{ textAlign: "center" }}>
                              <img src={product.miniThumb} alt="" style={{ maxWidth: "100%", width: "250px" }} />
                            </td>
                          </tr>
                          <tr>
                            <td style={{ textAlign: "center" }}>
                              <p style={{ marginBottom: "0px", marginTop: "0px", fontSize: "18px", fontFamily: "Calibri-bold", color: "#090A0A" }}> {series.name}</p>
                              <p style={{ marginBottom: "0px", marginTop: "0px" }}>{product.name}</p>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table style={{ width: "100%" }}>
                    <tr>
                      <td style={{ backgroundColor: "#090A0A", color: "#fff", padding: "4px 10px", fontSize: "14px" }} colSpan="3">
                        DATI VEICOLO
                      </td>
                    </tr>
                    <tr>
                      <td style={{ paddingRight: "10px" }}>
                        <ul style={{ listStyle: "none", paddingLeft: "0px", margin: "0px", marginTop: "5px" }}>
                          <li style={{ backgroundColor: "#878787", color: "#fff", marginBottom: "5px", padding: " 2px 10px", fontSize: "12px" }}>MARCA VEICOLO: {contactFormData.vehicleBrand && contactFormData.vehicleBrand.value}</li>
                          <li style={{ backgroundColor: "#878787", color: "#fff", padding: " 2px 10px", fontSize: "12px" }}>MODELLO VEICOLO: {contactFormData.vehicleModel && contactFormData.vehicleModel.value}</li>
                        </ul>
                      </td>
                      <td>
                        <ul style={{ listStyle: "none", paddingLeft: "0px", margin: "0px", marginTop: "5px" }}>
                          <li style={{ backgroundColor: "#878787", color: "#fff", marginBottom: "5px", padding: " 2px 10px", fontSize: "12px" }}>ANNO DI COSTRUZIONE: {contactFormData.yearOfConstruction && contactFormData.yearOfConstruction.value}</li>
                          <li style={{ backgroundColor: "#878787", color: "#fff", padding: " 2px 10px", fontSize: "12px" }}>INSERIRE NUMERO POSTI VETTURA: {contactFormData.noOfSeats && contactFormData.noOfSeats.value}</li>
                        </ul>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px 0px" }}>
                  <table style={{ width: "100%" }}>
                    <tr>
                      <td style={{ padding: "6px", textAlign: "center", borderTop: "1px solid #878787", borderBottom: "1px solid #878787", fontFamily: "Calibri-bold" }} colSpan="2">
                        SELEZIONI EFFETTUATE
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table style={{ width: "100%" }}>
                    <tr>
                      <td style={{ width: "50%", verticalAlign: "top" }}>

                        {/* <tr> */}
                        {config.series[seriesId].products[productId].historyArray.map((h, hI) => {
                          return history[h] ? (<>
                            {history[h].type === "color" ? (
                              <>
                                <table className="table-do-not-break" style={{ width: "100%", tableLayout: "fixed" }}>
                                  <tr>
                                    <td style={{ verticalAlign: "top", paddingRight: "6px" }}>
                                      <ul style={{ listStyle: "none", paddingLeft: "0px", margin: "0px" }}>
                                        <li style={{ backgroundColor: "#090A0A", color: "#fff", marginBottom: "5px", padding: "4px 10px", fontSize: "14px" }}>{history[h].history}</li>
                                        <li style={{ backgroundColor: "#878787", color: "#fff", marginBottom: "5px", padding: "3px 10px", fontSize: "12px" }}>{history[h].name}</li>
                                        <li style={{ backgroundColor: "#878787", color: "#fff", padding: " 2px 10px", fontSize: "12px" }}>{config.materials[history[h].selectedMat].name}{config.materials[history[h].selectedMat].code ? "-" + (config.materials[history[h].selectedMat].code) : ""}</li>
                                      </ul>
                                    </td>
                                    <td
                                      style={{
                                        backgroundColor: "#" + config.materials[history[h].selectedMat].color,
                                        backgroundSize: "auto 100%",
                                        width: "40%",
                                        backgroundRepeat: "no-repeat",
                                        backgroundPosition: "center",
                                      }}
                                    ></td>
                                  </tr>
                                  <tr>
                                    <td colSpan="2">
                                      <div style={{ backgroundColor: "#878787", height: "1px", marginTop: "9px", marginBottom: "9px", height: "1px" }}></div>
                                    </td>
                                  </tr>
                                </table>
                              </>
                            ) : <></>}
                            {history[h].type === "filati" || history[h].type === "design" || history[h].type === "traforature" || history[h].type === "embossed" ? (
                              <>
                                <table className="table-do-not-break" style={{ width: "100%", tableLayout: "fixed" }}>
                                  <tr>
                                    <td style={{ verticalAlign: "top", paddingRight: "6px" }}>
                                      <ul style={{ listStyle: "none", paddingLeft: "0px", margin: "0px" }}>
                                        <li style={{ backgroundColor: "#090A0A", color: "#fff", marginBottom: "5px", padding: "4px 10px", fontSize: "14px" }}>{history[h].history}</li>
                                        <li style={{ backgroundColor: "#878787", color: "#fff", marginBottom: "5px", padding: "3px 10px", fontSize: "12px" }}>{history[h].name}</li>
                                        <li style={{ backgroundColor: "#878787", color: "#fff", padding: " 2px 10px", fontSize: "12px" }}>{config.materials[history[h].selectedMat].name}{config.materials[history[h].selectedMat].code ? "-" + (config.materials[history[h].selectedMat].code) : ""}</li>
                                      </ul>
                                    </td>
                                    <td
                                      style={{
                                        backgroundImage: `url('/images/${history[h].type}/${config.materials[history[h].selectedMat].file}')`,
                                        backgroundSize: "auto 100%",
                                        width: "40%",
                                        backgroundRepeat: "no-repeat",
                                        backgroundPosition: "center",
                                      }}
                                    ></td>
                                  </tr>
                                  <tr>
                                    <td colSpan="2">
                                      <div style={{ backgroundColor: "#878787", height: "1px", marginTop: "9px", marginBottom: "9px", height: "1px" }}></div>
                                    </td>
                                  </tr>
                                </table>
                              </>
                            ) : <></>}

                          </>) : (<></>)
                        })}
                        {/* </table> */}
                      </td>
                      <td style={{ verticalAlign: "top" }}>
                        <table className="table-do-not-break" style={{ width: "100%" }}>
                          <tr>
                            <td style={{ paddingLeft: "10px" }}>
                              <img id="image1" alt="" style={{ width: "100%" }} />
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="2">
                              <div style={{ marginBottom: "1px" }}></div>
                            </td>
                          </tr>
                          <tr>
                            <td style={{ paddingLeft: "10px" }}>
                              <img id="image2" alt="" style={{ width: "100%" }} />
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </page>
      <div className="pagebreak"> </div>
      <page size="A4">
        <div className="page-container">
          <table>
            <tbody>
              <tr>
                <td style={{ verticalAlign: "top", paddingBottom: "15px" }}>
                  <table style={{ width: "100%" }}>
                    <tr>
                      <td>
                        <div className="header">
                          <div className="email-logo">
                            <img src="/images/logo-medici-atelier-dark.svg" />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table style={{ width: "100%" }}>
                    <tr>
                      <td style={{ paddingRight: "10px", paddingBottom: "6px" }}>
                        <img id="image3" src="/images/configurator-img.png" style={{ width: "100%", height: "100%" }} />
                      </td>
                      <td style={{ paddingRight: "10px", paddingBottom: "6px" }}>
                        <img id="image4" src="/images/configurator-img.png" style={{ width: "100%", height: "100%" }} />
                      </td>
                      <td style={{ paddingBottom: "6px" }}>
                        <img id="image5" src="/images/configurator-img.png" style={{ width: "100%", height: "100%" }} />
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table style={{ width: "100%" }}>
                    <tr>
                      <td style={{ backgroundColor: "#000", color: "#fff", marginBottom: "5px", padding: "6px 10px", marginBottom: "10px" }}>LAVORAZIONI OPZIONALI</td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table className="table-do-not-break" style={{ width: "100%", marginTop: "14px" }}>
                    <tr>
                      {config.series[seriesId].products[productId].catHistoryArray.map((catH, catHIndex) => {
                        return <>
                          <td style={{
                            padding: "0.4rem 1rem",
                            backgroundColor: catHistory[catH.code] ? "#0095db" : "#fff",
                            color: catHistory[catH.code] ? "#fff" : "#000",
                            textAlign: "center"
                          }}>{catH.name}</td>
                          <td width="5px"></td>
                        </>
                      })}
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table style={{ width: "100%", marginTop: "14px" }}>
                    <tbody>
                      <tr>
                        <td style={{ paddingRight: "10px", paddingBottom: "6px" }}>
                          <img id="image6" src="/images/configurator-img.png" style={{ width: "100%", height: "100%" }} />
                        </td>
                        <td style={{ paddingBottom: "6px" }}>
                          <img id="image7" src="/images/configurator-img.png" style={{ width: "100%", height: "100%" }} />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </page>
      <div className="pagebreak"> </div>
      <page size="A4">
        <div className="page-container">
          <table>
            <tbody>
              <tr>
                <td style={{ verticalAlign: "top", paddingBottom: "15px" }}>
                  <table style={{ width: "100%" }}>
                    <tr>
                      <td>
                        <div className="header">
                          <div className="email-logo">
                            <img src="/images/logo-medici-atelier-dark.svg" />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table style={{ width: "100%", marginTop: "14px" }}>
                    <tbody>
                      <tr>
                        <td colSpan="2">
                          <img id="image8" src="/images/configurator-img.png" style={{ width: "100%", height: "100%" }} />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </page>
      <div className="pagebreak"> </div>
      <page size="A4">
        <div className="page-container">
          <table>
            <tbody>
              <tr>
                <td style={{ verticalAlign: "top", paddingBottom: "15px" }}>
                  <table style={{ width: "100%" }}>
                    <tr>
                      <td>
                        <div className="header">
                          <div className="logo email-logo">
                            <img src="/images/logo-medici-atelier-dark.svg" />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table className="table-do-not-break" style={{ width: "100%" }}>
                    <tr>
                      <td style={{ paddingBottom: "6px" }}>
                        <img id="image9" src="/images/configurator-img.png" style={{ width: "100%", height: "100%" }} />
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </page>

      <div className="pagebreak"> </div>
      <page size="A4">
        <div className="page-container">
          <table>
            <tbody>
              <tr>
                <td style={{ verticalAlign: "top", paddingBottom: "15px" }}>
                  <table style={{ width: "100%" }}>
                    <tr>
                      <td>
                        <div className="header">
                          <div className="logo email-logo">
                            <img src="/images/logo-medici-atelier-dark.svg" />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table className="table-do-not-break" style={{ width: "100%" }}>
                    <tr>
                      <td style={{ paddingBottom: "16px" }}>
                        <img id="image10" src="/images/configurator-img.png" style={{ width: "100%", height: "100%" }} />
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </page>
    </>
  );
}
