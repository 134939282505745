import React from "react";

import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

import Header from "../components/Header";
import Home from "./Home";
import Series from "./Series";
import history from "./BrowserHistory";
import Configurator from "./Configurator";
import EmailTemplateHTML from "./EmailTemplateHTML";

export default function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Redirect to="/it" />
        </Route>
        <Route exact path="/:lang">
          <Home />
        </Route>
        <Route path="/:lang/collections">
          <Header />
          <Series />
        </Route>
        <Route
          path="/:lang/:seriesId/:productId/config"
          render={(props) => (
            <>
              <Header />
              <Configurator />
            </>
          )}
        />
        <Route
          path="/:lang/:seriesId/:productId/email-html"
          render={(props) => (
            <>
              <EmailTemplateHTML {...props} />
            </>
          )}
        />
      </Switch>
    </Router>
  );
}
