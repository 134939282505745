import { useParams, Link } from "react-router-dom";
import { config } from "../data";
import "../scss/main.scss";
import "../styles/developer.scss";


export default function Header() {

  const { lang, seriesId, productId } = useParams();

  const series = seriesId ? config.series[seriesId] : null;
  const product = productId ? series.products[productId] : null;
  const productInfo = product ? config.productInfo.get(seriesId, productId) : null;

  // var langError = true;
  if (
    config.languages.find((L) => {
      return L === lang;
    }) == null
  ) {
    // throw new Error("language not valid : " + lang);
  }
  return (
    <header>
      <div className="w-100 logo">
        <Link to={"/" + lang + "/"}>
          <img src="/images/logo-medici-atelier-dark.svg" alt="" className="img-fuild w-100 logo" />
        </Link>
      </div>
      <div className="logo-header"></div>
      {series && (
        <div className="segment" key={"segment-11"}>
          <img src={product.thumbWhite} alt="" />
          <h6>{productInfo.seriesName}</h6>
          <p>{productInfo.productName}</p>
        </div>
      )}
    </header>
  );
}
