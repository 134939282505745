// Esempio di catHistoryArray, nella build.
// const catHistoryArray = [
//     { name: "SEDILE", code: "sedile", mandatory: true },
//     { name: "PANNELLO PORTA", code: "pannello_porta", mandatory: true },
//     { name: "FILATI", code: "filati", mandatory: false },
// ];

//esempio di catHistory, che contiene le sezioni configurate.
// const catHistory= {
//     filati: false,
//     pannello_porta: true,
//     sedile: true
// };

export default function checkMandatorySections(catHistoryArray, catHistory) {
    for (const item of catHistoryArray) {
      if (item.mandatory) {
        // Controlla se il codice corrispondente in catHistory è settato a true
        if (!catHistory[item.code]) {
          // Se anche un solo elemento mandatory non è true, ritorna false
          return false;
        }
      }
    }
    // Se tutti i mandatory sono true, ritorna true
    return true;
  }