export default function LoadProductInfo() {
  const productInfo = {};

  function add(seriesId, productId, seriesName, productName, description, confURL, itURL, enURL, frURL, deURL, esURL, chURL, euURL, atURL, ukURL, usURL) {
    const product = {};

    product.seriesId = seriesId;
    product.productId = productId;
    product.seriesName = seriesName;
    product.productName = productName;
    product.description = description;
    product.confURL = confURL;

    product.webURL = {};
    product.webURL["it"] = itURL;
    product.webURL["en"] = enURL;
    product.webURL["fr"] = frURL;
    product.webURL["de"] = deURL;
    product.webURL["es"] = esURL;
    product.webURL["ch"] = chURL;
    product.webURL["eu"] = euURL;
    product.webURL["at"] = atURL;
    product.webURL["uk"] = ukURL;
    product.webURL["us"] = usURL;

    productInfo[seriesId + "." + productId] = product;
  }

  add("ab", "0", "SEGMENTI A / B", "SMALL CAR", "placeholder descr", "", "", "", "", "", "", "", "", "", "", "");
  add("cd", "0", "SEGMENTI C / D", "FAMILY CAR", "placeholder descr", "", "", "", "", "", "", "", "", "", "", "");
  add("ef", "0", "SEGMENTI E / F", "PREMIUM & SUV", "placeholder descr", "", "", "", "", "", "", "", "", "", "", "");
  add("super", "0", "SUPERCAR", "LUX", "placeholder descr", "", "", "", "", "", "", "", "", "", "", "");

  productInfo.get = function (seriesId, productId) {
    const productKey = seriesId + "." + productId;
    const product = productInfo[productKey];

    if (product == null) {
      // product info not found
      console.log('LoadProductInfo: "' + productKey + '" product not found');
    }

    return product;
  };

  return productInfo;
}
