import { series } from "../data/series";
import LoadProductInfo from "../data/LoadProductInfo";
import { materials } from "../data/materials";

export const config = {
  languages: ["it", "en", "fr", "de", "es", "ch", "eu", "at", "uk", "us"],
  series: series,
  productInfo: LoadProductInfo(),
  materials: materials
};
