import { createPortal } from 'react-dom';



export default function Popup({ onClose }) {
  return createPortal(
    <>
      <div className="popup-backdrop" />
      <dialog id="popup-modal" open>
        <h2>!!! ATTENZIONE !!!</h2>
        <p>La versione per smartphone è attualmente in versione beta test.</p>
        <p>Visto la dimensione dei file trattati, non siamo in grado di garantire il corretto funzionamento su tutti i modelli di cellulare, per verificare il corretto funzionamento si consiglia di essere connessi ad una rete WiFi.</p>
        <p>Nonostante la tipologia di connessione, anche la qualità della stessa o il software dello smartphone poterebbero inficiare l'utilizzo del configuratore da smartphone.</p>
        <p>Se dovessi riscontrare problemi di navigazione dallo smartphone suggeriamo di utilizzare una postazione desktop (computer), tramite la quale potrai godere della migliore qualità dell'esperienza di simulazione.</p>
        <p>Per qualsiasi necessità non esitate a scrivere a: <a href="mailto:support@medicistyle.it">support@medicistyle.it</a></p>
        <p id="popup-actions">
          <button onClick={onClose}>Close</button>
        </p>
      </dialog>
    </>,
    document.getElementById('modal')
  );
}
