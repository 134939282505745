import { Link, Redirect, useHistory, useParams, Switch, Route, useRouteMatch } from "react-router-dom";

export default function ConfiguratorFooter({ instructions, setInstructions }) {
    let { path, url } = useRouteMatch();
    const { lang, seriesId, productId } = useParams();
    return <footer style={{ opacity: instructions ? '0' : '1', position: instructions ? "absolute" : "relative" }}>
         <div className="social-media d-flex align-items-center">
            <a className="font-bold mb-0 text-white"  target="_blank" href="https://www.medicistyle.com/">medicistyle.atelier</a>
            <ul className="list-inline ml-xs-2 mb-0 d-flex">
            <li className="list-inline-item d-flex align-items-center">
              <a target="_blank" href="https://www.facebook.com/mediciinterniperauto" className="social-facebook" >
              </a>
            </li>
            <li className="list-inline-item d-flex align-items-center">
              <a target="_blank" className="social-instagram" href="https://www.instagram.com/medicistyleatelier/" ></a>
            </li>
            <li className="list-inline-item d-flex align-items-center">
              <a target="_blank" className="social-linkedin" href="https://www.linkedin.com/company/medici-style-srl/" ></a>
            </li>
          </ul>
        </div>
        <div className="entry-footer d-md-flex flex-grow-1 align-items-center">
            <p className="font-bold mb-md-0">info@medicistyle.it</p>
            <div className="custom-btn-group ms-auto">
                <Link className="btn btn-primary fs-sm-15 text-uppercase mx-1" to={`/${lang}/collections`}>
                    Indietro
                </Link>
                &nbsp; &nbsp;
                <button className="btn btn-primary fs-sm-15 text-uppercase mx-1" onClick={() => { setInstructions(true); console.log("istruzioni") }}>
                    Istruzioni
                </button>
                &nbsp; &nbsp;
                <Link className="btn btn-primary fs-sm-15 text-uppercase mx-1" to={`${url}/finish-configuration`}>Continua</Link>
                &nbsp; &nbsp;
            </div>
        </div>
    </footer>
}