export const materials = {
  // -----------
  // Pelle nappa
  // -----------
  // "1215","1241","1236","1207","1240","1227","1221","1239","1225","1237","1223","1200","1228","1243","1238","1210","1229","1232","1218","1205","1234","1208","1233"

  "1215": { code: "1215", name: "LATTE", color: "E9DFC8" },
  "1241": { code: "1241", name: "CINDER", color: "96A09B" },
  "1236": { code: "1236", name: "TORMALINA ", color: "373B3B" },
  "1207": { code: "1207", name: "NERO", color: "383A36" },
  "1240": { code: "1240", name: "ESPRESSO", color: "47342C" },
  "1227": { code: "1227", name: "CORTECCIA", color: "5E3C2C" },
  "1221": { code: "1221", name: "NOCE", color: "615142" },
  "1239": { code: "1239", name: "COCCO NUT", color: "895333" },
  "1225": { code: "1225", name: "BISCOTTO", color: "9D6840" },
  "1237": { code: "1237", name: "MOU", color: "9B714F" },
  "1223": { code: "1223", name: "CAPPUCCINO", color: "A37F57" },
  "1200": { code: "1200", name: "BEIGE", color: "C4AE7F" },
  "1228": { code: "1228", name: "KAYAK", color: "B5823F" },
  "1243": { code: "1243", name: "MANDARIN", color: "E36725" },
  "1238": { code: "1238", name: "ROSSO FERRARI", color: "B4262E" },
  "1210": { code: "1210", name: "ROSSO ANTICO", color: "873034" },
  "1229": { code: "1229", name: "MOSTO", color: "75332E" },
  "1232": { code: "1232", name: "ABETE", color: "2D4233" },
  "1218": { code: "1218", name: "ALOE", color: "525A3B" },
  "1205": { code: "1205", name: "GRIGIO", color: "515853" },
  "1234": { code: "1234", name: "AVIO ", color: "525C68" },
  "1208": { code: "1208", name: "NOTTE", color: "394C50" },
  "1233": { code: "1233", name: "TURQUAOISE", color: "007A87" },

  // ------------
  // Pelle stampa
  // ------------
  //  "00s", "01s", "02s", "03s", "04s", "05s", "06s", "07s", "08s", "09s", "10s", "11s", "12s", "13s", "14s", "15s", "16s", "17s", "18s",
  "00s": { code: "CL01", name: "NERO", color: "262621", slug: "stampa" },
  "01s": { code: "CL99", name: "ANTRACITE", color: "3A3A3A", slug: "stampa" },
  "02s": { code: "CL55", name: "BLU", color: "383D4C", slug: "stampa" },
  "03s": { code: "CL42", name: "GRIGIO MEDIO", color: "6A6C69", slug: "stampa" },
  "04s": { code: "CL7115", name: "GRIGIO GHIACCIO", color: "989891", slug: "stampa" },
  "05s": { code: "CL8117", name: "CIOCCOLATO", color: "563F37", slug: "stampa" },
  "06s": { code: "CL98", name: "TABACCO", color: "7C5C3E", slug: "stampa" },
  "07s": { code: "CL1134", name: "SABBIA", color: "8A7A5A", slug: "stampa" },
  "08s": { code: "CL12", name: "CREMA", color: "AE9868", slug: "stampa" },
  "09s": { code: "CL1136", name: "PANNA", color: "9D9378", slug: "stampa" },
  "10s": { code: "CL08", name: "BORDEAUX", color: "6B2B2D", slug: "stampa" },
  "11s": { code: "CL3121", name: "CARTIER", color: "802D2A", slug: "stampa" },
  "12s": { code: "CL115", name: "ROSSO CORSA", color: "AA302E", slug: "stampa" },
  "13s": { code: "CL307", name: "ARANCIO", color: "DD5E12", slug: "stampa" },
  "14s": { code: "CL1137", name: "AVORIO", color: "9D988B", slug: "stampa" },
  "15s": { code: "CL301", name: "BIANCO", color: "FDFDFD", slug: "stampa" },
  "16s": { code: "CL305", name: "BLU ELETTRICO", color: "00518D", slug: "stampa" },
  "17s": { code: "CL303", name: "GIALLO", color: "EDE600", slug: "stampa" },
  "18s": { code: "CL304", name: "VERDE FLUO", color: "00DC3C", slug: "stampa" },

  // ----------
  // Alcantara
  // ----------
  // "AL01", "AL02", "AL03", "AL04", "AL05", "AL06", "AL07", "AL08", "AL09", "AL010", "AL011", "AL012", "AL013", "AL014", "AL015",
  "AL01": { code: "2934", name: "GRLGIO MEDIO", color: "B0B1A4", slug: "alcantara" },
  "AL02": { code: "9002", name: "ANTRACITE", color: "3A3A3A", slug: "alcantara" },
  "AL03": { code: "6408", name: "BLU ELETTRICO", color: "344977", slug: "alcantara" },
  "AL04": { code: "6833", name: "CIOCCOLATO", color: "4F3F34", slug: "alcantara" },
  "AL05": { code: "9076", name: "CILIEGIA", color: "77342A", slug: "alcantara" },
  "AL06": { code: "2901", name: "BEIGE", color: "BDAF7F", slug: "alcantara" },
  "AL07": { code: "9040", name: "NERO", color: "262626", slug: "alcantara" },
  "AL08": { code: "0080", name: "GHIACCIO", color: "D9DDCE", slug: "alcantara" },
  "AL09": { code: "9052", name: "GRIGIO SCURO", color: "505050", slug: "alcantara" },
  "AL010": { code: "2910", name: "MARRONE SCURO", color: "5D371C", slug: "alcantara" },
  "AL011": { code: "2911", name: "PANNA", color: "D6CBAA", slug: "alcantara" },
  "AL012": { code: "9131", name: "PAPRIKA", color: "804437", slug: "alcantara" },
  "AL013": { code: "2930", name: "TABACCO", color: "794D2E", slug: "alcantara" },
  "AL014": { code: "4996", name: "ROSSO FERRARI", color: "B23131", slug: "alcantara" },
  "AL015": { code: "5310", name: "MATTONE", color: "B97355", slug: "alcantara" },

  // ----
  // Filo
  // ----
  // "P10", "A520", "A513", "P3", "P92", "A830", "A415", "A822", "A717", "P775", "P573", "A308", "A0010", "A323", "A406", "P407", "A932", "A0019", "A0017", "A617", "A614", "A0020"
  "no_filati": { name: "OFF", code: "", color: "" },
  "P10": { name: "NERO", code: "A012", color: "2B2A28" },
  "A520": { name: "ANTRACITE", code: "A520", color: "665B5E" },
  "A513": { name: "GRIGIO", code: "A513", color: "A4A7A7" },
  "P3": { name: "GHIACCIO", code: "A507", color: "C1C2C2" },
  "P92": { name: "BIANCO", code: "A103", color: "FAFAFA" },
  "A830": { name: "CIOCCOLATO", code: "A830", color: "734F47" },
  "A415": { name: "BORDEAUX", code: "A415", color: "714443" },
  "A822": { name: "TABACCO", code: "A822", color: "926852" },
  "A717": { name: "SABBIA", code: "A717", color: "C3A881" },
  "P775": { name: "CREMA", code: "A712", color: "E8DFB3" },
  "P573": { name: "PANNA", code: "A712", color: "D0C9B4" },
  "A308": { name: "GIALLO", code: "A308", color: "DFBC58" },
  "A0010": { name: "ARANCIONE", code: "A323", color: "C85C3A" },
  "A323": { name: "ROSSO", code: "A406", color: "C14239" },
  "A406": { name: "CARTIER", code: "A407", color: "954448" },
  "P407": { name: "VERDE", code: "A932", color: "556757" },
  "A932": { name: "BLU", code: "A617", color: "595375" },
  "A0019": { name: "BLU ELETTRICO", code: "A614", color: "465B99" },
  "A0017": { name: "GIALLO FLUO", code: "S1426", color: "E2D562" },
  "A617": { name: "VERDE FLUO", code: "A923", color: "D0D375" },
  "A614": { name: "TURCHESE", code: "A610", color: "007C86" },
  "A0020": { name: "VIOLA", code: "S7377", color: "860083" },

  // // FILATI PARTI RIGIDE
  // // ----
  // "no_filati_parti_rigide", "FR10","FR520","FR513","FR3","FR92","FR830","FR415","FR822","FR717","FR775","FR573","FR308","FR0010","FR323","FR406","FR407","FR932","FR0019","FR0017","FR617","FR614","FR0020"
  "no_filati_parti_rigide": { name: "OFF", code: "", color: "", file: "filati-off.png" },
  "FR10": { name: "NERO", code: "A012", color: "2B2A28", file: "filati-nero.png" },
  "FR520": { name: "ANTRACITE", code: "A520", color: "665B5E", file: "filati-antracite.png" },
  "FR513": { name: "GRIGIO", code: "A513", color: "A4A7A7", file: "filati-grigio.png" },
  "FR3": { name: "GHIACCIO", code: "A507", color: "C1C2C2", file: "filati-ghiaccio.png" },
  "FR92": { name: "BIANCO", code: "A103", color: "FAFAFA", file: "filati-bianco.png" },
  "FR830": { name: "CIOCCOLATO", code: "A830", color: "734F47", file: "filati-cioccolato.png" },
  "FR415": { name: "BORDEAUX", code: "A415", color: "714443", file: "filati-bordeaux.png" },
  "FR822": { name: "TABACCO", code: "A822", color: "926852", file: "filati-tabacco.png" },
  "FR717": { name: "SABBIA", code: "A717", color: "C3A881", file: "filati-sabbia.png" },
  "FR775": { name: "CREMA", code: "A712", color: "E8DFB3", file: "filati-crema.png" },
  "FR573": { name: "PANNA", code: "A712", color: "D0C9B4", file: "filati-panna.png" },
  "FR308": { name: "GIALLO", code: "A308", color: "DFBC58", file: "filati-giallo.png" },
  "FR0010": { name: "ARANCIONE", code: "A323", color: "C85C3A", file: "filati-arancione.png" },
  "FR323": { name: "ROSSO", code: "A406", color: "C14239", file: "filati-rosso.png" },
  "FR406": { name: "CARTIER", code: "A407", color: "954448", file: "filati-cartier.png" },
  "FR407": { name: "VERDE", code: "A932", color: "556757", file: "filati-verde.png" },
  "FR932": { name: "BLU", code: "A617", color: "595375", file: "filati-blu.png" },
  "FR0019": { name: "BLU ELETTRICO", code: "A614", color: "465B99", file: "filati-blu-elettrico.png" },
  "FR0017": { name: "GIALLO FLUO", code: "S1426", color: "E2D562", file: "filati-giallo-fluo.png" },
  "FR617": { name: "VERDE FLUO", code: "A923", color: "D0D375", file: "filati-verde-fluo.png" },
  "FR614": { name: "TURCHESE", code: "A610", color: "007C86", file: "filati-turchese.png" },
  "FR0020": { name: "VIOLA", code: "S7377", color: "860083", file: "filati-viola.png" },

  // "no_filati_sedile", "FS10","FS520","FS513","FS3","FS92","FS830","FS415","FS822","FS717","FS775","FS573","FS308","FS0010","FS323","FS406","FS407","FS932","FS0019","FS0017","FS617","FS614","FS0020"
  "no_filati_sedile": { name: "OFF", code: "", color: "", file: "filati-off.png" },
  "FS10": { name: "NERO", code: "A012", color: "2B2A28", file: "filati-nero.png" },
  "FS520": { name: "ANTRACITE", code: "A520", color: "665B5E", file: "filati-antracite.png" },
  "FS513": { name: "GRIGIO", code: "A513", color: "A4A7A7", file: "filati-grigio.png" },
  "FS3": { name: "GHIACCIO", code: "A507", color: "C1C2C2", file: "filati-ghiaccio.png" },
  "FS92": { name: "BIANCO", code: "A103", color: "FAFAFA", file: "filati-bianco.png" },
  "FS830": { name: "CIOCCOLATO", code: "A830", color: "734F47", file: "filati-cioccolato.png" },
  "FS415": { name: "BORDEAUX", code: "A415", color: "714443", file: "filati-bordeaux.png" },
  "FS822": { name: "TABACCO", code: "A822", color: "926852", file: "filati-tabacco.png" },
  "FS717": { name: "SABBIA", code: "A717", color: "C3A881", file: "filati-sabbia.png" },
  "FS775": { name: "CREMA", code: "A712", color: "E8DFB3", file: "filati-crema.png" },
  "FS573": { name: "PANNA", code: "A712", color: "D0C9B4", file: "filati-panna.png" },
  "FS308": { name: "GIALLO", code: "A308", color: "DFBC58", file: "filati-giallo.png" },
  "FS0010": { name: "ARANCIONE", code: "A323", color: "C85C3A", file: "filati-arancione.png" },
  "FS323": { name: "ROSSO", code: "A406", color: "C14239", file: "filati-rosso.png" },
  "FS406": { name: "CARTIER", code: "A407", color: "954448", file: "filati-cartier.png" },
  "FS407": { name: "VERDE", code: "A932", color: "556757", file: "filati-verde.png" },
  "FS932": { name: "BLU", code: "A617", color: "595375", file: "filati-blu.png" },
  "FS0019": { name: "BLU ELETTRICO", code: "A614", color: "465B99", file: "filati-blu-elettrico.png" },
  "FS0017": { name: "GIALLO FLUO", code: "S1426", color: "E2D562", file: "filati-giallo-fluo.png" },
  "FS617": { name: "VERDE FLUO", code: "A923", color: "D0D375", file: "filati-verde-fluo.png" },
  "FS614": { name: "TURCHESE", code: "A610", color: "007C86", file: "filati-turchese.png" },
  "FS0020": { name: "VIOLA", code: "S7377", color: "860083", file: "filati-viola.png" },


  // "no_filati_design", "FD10","FD520","FD513","FD3","FD92","FD830","FD415","FD822","FD717","FD775","FD573","FD308","FD0010","FD323","FD406","FD407","FD932","FD0019","FD0017","FD617","FD614","FD0020"
  "no_filati_design": { name: "OFF", code: "", color: "", file: "filati-off.png" },
  "FD10": { name: "NERO", code: "A012", color: "2B2A28", file: "filati-nero.png" },
  "FD520": { name: "ANTRACITE", code: "A520", color: "665B5E", file: "filati-antracite.png" },
  "FD513": { name: "GRIGIO", code: "A513", color: "A4A7A7", file: "filati-grigio.png" },
  "FD3": { name: "GHIACCIO", code: "A507", color: "C1C2C2", file: "filati-ghiaccio.png" },
  "FD92": { name: "BIANCO", code: "A103", color: "FAFAFA", file: "filati-bianco.png" },
  "FD830": { name: "CIOCCOLATO", code: "A830", color: "734F47", file: "filati-cioccolato.png" },
  "FD415": { name: "BORDEAUX", code: "A415", color: "714443", file: "filati-bordeaux.png" },
  "FD822": { name: "TABACCO", code: "A822", color: "926852", file: "filati-tabacco.png" },
  "FD717": { name: "SABBIA", code: "A717", color: "C3A881", file: "filati-sabbia.png" },
  "FD775": { name: "CREMA", code: "A712", color: "E8DFB3", file: "filati-crema.png" },
  "FD573": { name: "PANNA", code: "A712", color: "D0C9B4", file: "filati-panna.png" },
  "FD308": { name: "GIALLO", code: "A308", color: "DFBC58", file: "filati-giallo.png" },
  "FD0010": { name: "ARANCIONE", code: "A323", color: "C85C3A", file: "filati-arancione.png" },
  "FD323": { name: "ROSSO", code: "A406", color: "C14239", file: "filati-rosso.png" },
  "FD406": { name: "CARTIER", code: "A407", color: "954448", file: "filati-cartier.png" },
  "FD407": { name: "VERDE", code: "A932", color: "556757", file: "filati-verde.png" },
  "FD932": { name: "BLU", code: "A617", color: "595375", file: "filati-blu.png" },
  "FD0019": { name: "BLU ELETTRICO", code: "A614", color: "465B99", file: "filati-blu-elettrico.png" },
  "FD0017": { name: "GIALLO FLUO", code: "S1426", color: "E2D562", file: "filati-giallo-fluo.png" },
  "FD617": { name: "VERDE FLUO", code: "A923", color: "D0D375", file: "filati-verde-fluo.png" },
  "FD614": { name: "TURCHESE", code: "A610", color: "007C86", file: "filati-turchese.png" },
  "FD0020": { name: "VIOLA", code: "S7377", color: "860083", file: "filati-viola.png" },

  // ----
  // Filati volante
  // ----
  // "no_filati_volante", "PVP10","PVA520","PVA513","PVP3","PVP92","PVA830","PVA415","PVA822","PVA717","PVP775","PVP573","PVA308","PVA0010","PVA323","PVA406","PVP407","PVA932","PVA0019","PVA0017","PVA617","PVA614","PVA0020"
  "no_filati_volante": { name: "OFF", code: "", color: "", file: "filati-off.png" },
  "PVP10": { name: "NERO", code: "A012", color: "2B2A28", file: "filati-nero.png" },
  "PVA520": { name: "ANTRACITE", code: "A520", color: "665B5E", file: "filati-antracite.png" },
  "PVA513": { name: "GRIGIO", code: "A513", color: "A4A7A7", file: "filati-grigio.png" },
  "PVP3": { name: "GHIACCIO", code: "A507", color: "C1C2C2", file: "filati-ghiaccio.png" },
  "PVP92": { name: "BIANCO", code: "A103", color: "FAFAFA", file: "filati-bianco.png" },
  "PVA830": { name: "CIOCCOLATO", code: "A830", color: "734F47", file: "filati-cioccolato.png" },
  "PVA415": { name: "BORDEAUX", code: "A415", color: "714443", file: "filati-bordeaux.png" },
  "PVA822": { name: "TABACCO", code: "A822", color: "926852", file: "filati-tabacco.png" },
  "PVA717": { name: "SABBIA", code: "A717", color: "C3A881", file: "filati-sabbia.png" },
  "PVP775": { name: "CREMA", code: "A712", color: "E8DFB3", file: "filati-crema.png" },
  "PVP573": { name: "PANNA", code: "A712", color: "D0C9B4", file: "filati-panna.png" },
  "PVA308": { name: "GIALLO", code: "A308", color: "DFBC58", file: "filati-giallo.png" },
  "PVA0010": { name: "ARANCIONE", code: "A323", color: "C85C3A", file: "filati-arancione.png" },
  "PVA323": { name: "ROSSO", code: "A406", color: "C14239", file: "filati-rosso.png" },
  "PVA406": { name: "CARTIER", code: "A407", color: "954448", file: "filati-cartier.png" },
  "PVP407": { name: "VERDE", code: "A932", color: "556757", file: "filati-verde.png" },
  "PVA932": { name: "BLU", code: "A617", color: "595375", file: "filati-blu.png" },
  "PVA0019": { name: "BLU ELETTRICO", code: "A614", color: "465B99", file: "filati-blu-elettrico.png" },
  "PVA0017": { name: "GIALLO FLUO", code: "S1426", color: "E2D562", file: "filati-giallo-fluo.png" },
  "PVA617": { name: "VERDE FLUO", code: "A923", color: "D0D375", file: "filati-verde-fluo.png" },
  "PVA614": { name: "TURCHESE", code: "A610", color: "007C86", file: "filati-turchese.png" },
  "PVA0020": { name: "VIOLA", code: "S7377", color: "860083", file: "filati-viola.png" },


  // "no_filati_bracciolo_centrale", "FB10","FB520","FB513","FB3","FB92","FB830","FB415","FB822","FB717","FB775","FB573","FB308","FB0010","FB323","FB406","FB407","FB932","FB0019","FB0017","FB617","FB614","FB0020"
  "no_filati_bracciolo_centrale": { name: "OFF", code: "", color: "", file: "filati-off.png" },
  "FB10": { name: "NERO", code: "A012", color: "2B2A28", file: "filati-nero.png" },
  "FB520": { name: "ANTRACITE", code: "A520", color: "665B5E", file: "filati-antracite.png" },
  "FB513": { name: "GRIGIO", code: "A513", color: "A4A7A7", file: "filati-grigio.png" },
  "FB3": { name: "GHIACCIO", code: "A507", color: "C1C2C2", file: "filati-ghiaccio.png" },
  "FB92": { name: "BIANCO", code: "A103", color: "FAFAFA", file: "filati-bianco.png" },
  "FB830": { name: "CIOCCOLATO", code: "A830", color: "734F47", file: "filati-cioccolato.png" },
  "FB415": { name: "BORDEAUX", code: "A415", color: "714443", file: "filati-bordeaux.png" },
  "FB822": { name: "TABACCO", code: "A822", color: "926852", file: "filati-tabacco.png" },
  "FB717": { name: "SABBIA", code: "A717", color: "C3A881", file: "filati-sabbia.png" },
  "FB775": { name: "CREMA", code: "A712", color: "E8DFB3", file: "filati-crema.png" },
  "FB573": { name: "PANNA", code: "A712", color: "D0C9B4", file: "filati-panna.png" },
  "FB308": { name: "GIALLO", code: "A308", color: "DFBC58", file: "filati-giallo.png" },
  "FB0010": { name: "ARANCIONE", code: "A323", color: "C85C3A", file: "filati-arancione.png" },
  "FB323": { name: "ROSSO", code: "A406", color: "C14239", file: "filati-rosso.png" },
  "FB406": { name: "CARTIER", code: "A407", color: "954448", file: "filati-cartier.png" },
  "FB407": { name: "VERDE", code: "A932", color: "556757", file: "filati-verde.png" },
  "FB932": { name: "BLU", code: "A617", color: "595375", file: "filati-blu.png" },
  "FB0019": { name: "BLU ELETTRICO", code: "A614", color: "465B99", file: "filati-blu-elettrico.png" },
  "FB0017": { name: "GIALLO FLUO", code: "S1426", color: "E2D562", file: "filati-giallo-fluo.png" },
  "FB617": { name: "VERDE FLUO", code: "A923", color: "D0D375", file: "filati-verde-fluo.png" },
  "FB614": { name: "TURCHESE", code: "A610", color: "007C86", file: "filati-turchese.png" },
  "FB0020": { name: "VIOLA", code: "S7377", color: "860083", file: "filati-viola.png" },


  // "no_filati_tunnel_centrale", "FT10","FT520","FT513","FT3","FT92","FT830","FT415","FT822","FT717","FT775","FT573","FT308","FT0010","FT323","FT406","FT407","FT932","FT0019","FT0017","FT617","FT614","FT0020"
  "no_filati_tunnel_centrale": { name: "OFF", code: "", color: "", file: "filati-off.png" },
  "FT10": { name: "NERO", code: "A012", color: "2B2A28", file: "filati-nero.png" },
  "FT520": { name: "ANTRACITE", code: "A520", color: "665B5E", file: "filati-antracite.png" },
  "FT513": { name: "GRIGIO", code: "A513", color: "A4A7A7", file: "filati-grigio.png" },
  "FT3": { name: "GHIACCIO", code: "A507", color: "C1C2C2", file: "filati-ghiaccio.png" },
  "FT92": { name: "BIANCO", code: "A103", color: "FAFAFA", file: "filati-bianco.png" },
  "FT830": { name: "CIOCCOLATO", code: "A830", color: "734F47", file: "filati-cioccolato.png" },
  "FT415": { name: "BORDEAUX", code: "A415", color: "714443", file: "filati-bordeaux.png" },
  "FT822": { name: "TABACCO", code: "A822", color: "926852", file: "filati-tabacco.png" },
  "FT717": { name: "SABBIA", code: "A717", color: "C3A881", file: "filati-sabbia.png" },
  "FT775": { name: "CREMA", code: "A712", color: "E8DFB3", file: "filati-crema.png" },
  "FT573": { name: "PANNA", code: "A712", color: "D0C9B4", file: "filati-panna.png" },
  "FT308": { name: "GIALLO", code: "A308", color: "DFBC58", file: "filati-giallo.png" },
  "FT0010": { name: "ARANCIONE", code: "A323", color: "C85C3A", file: "filati-arancione.png" },
  "FT323": { name: "ROSSO", code: "A406", color: "C14239", file: "filati-rosso.png" },
  "FT406": { name: "CARTIER", code: "A407", color: "954448", file: "filati-cartier.png" },
  "FT407": { name: "VERDE", code: "A932", color: "556757", file: "filati-verde.png" },
  "FT932": { name: "BLU", code: "A617", color: "595375", file: "filati-blu.png" },
  "FT0019": { name: "BLU ELETTRICO", code: "A614", color: "465B99", file: "filati-blu-elettrico.png" },
  "FT0017": { name: "GIALLO FLUO", code: "S1426", color: "E2D562", file: "filati-giallo-fluo.png" },
  "FT617": { name: "VERDE FLUO", code: "A923", color: "D0D375", file: "filati-verde-fluo.png" },
  "FT614": { name: "TURCHESE", code: "A610", color: "007C86", file: "filati-turchese.png" },
  "FT0020": { name: "VIOLA", code: "S7377", color: "860083", file: "filati-viola.png" },


  // "no_filati_cruscotto", "FC10","FC520","FC513","FC3","FC92","FC830","FC415","FC822","FC717","FC775","FC573","FC308","FC0010","FC323","FC406","FC407","FC932","FC0019","FC0017","FC617","FC614","FC0020"
  "no_filati_cruscotto": { name: "OFF", code: "", color: "", file: "filati-off.png" },
  "FC10": { name: "NERO", code: "A012", color: "2B2A28", file: "filati-nero.png" },
  "FC520": { name: "ANTRACITE", code: "A520", color: "665B5E", file: "filati-antracite.png" },
  "FC513": { name: "GRIGIO", code: "A513", color: "A4A7A7", file: "filati-grigio.png" },
  "FC3": { name: "GHIACCIO", code: "A507", color: "C1C2C2", file: "filati-ghiaccio.png" },
  "FC92": { name: "BIANCO", code: "A103", color: "FAFAFA", file: "filati-bianco.png" },
  "FC830": { name: "CIOCCOLATO", code: "A830", color: "734F47", file: "filati-cioccolato.png" },
  "FC415": { name: "BORDEAUX", code: "A415", color: "714443", file: "filati-bordeaux.png" },
  "FC822": { name: "TABACCO", code: "A822", color: "926852", file: "filati-tabacco.png" },
  "FC717": { name: "SABBIA", code: "A717", color: "C3A881", file: "filati-sabbia.png" },
  "FC775": { name: "CREMA", code: "A712", color: "E8DFB3", file: "filati-crema.png" },
  "FC573": { name: "PANNA", code: "A712", color: "D0C9B4", file: "filati-panna.png" },
  "FC308": { name: "GIALLO", code: "A308", color: "DFBC58", file: "filati-giallo.png" },
  "FC0010": { name: "ARANCIONE", code: "A323", color: "C85C3A", file: "filati-arancione.png" },
  "FC323": { name: "ROSSO", code: "A406", color: "C14239", file: "filati-rosso.png" },
  "FC406": { name: "CARTIER", code: "A407", color: "954448", file: "filati-cartier.png" },
  "FC407": { name: "VERDE", code: "A932", color: "556757", file: "filati-verde.png" },
  "FC932": { name: "BLU", code: "A617", color: "595375", file: "filati-blu.png" },
  "FC0019": { name: "BLU ELETTRICO", code: "A614", color: "465B99", file: "filati-blu-elettrico.png" },
  "FC0017": { name: "GIALLO FLUO", code: "S1426", color: "E2D562", file: "filati-giallo-fluo.png" },
  "FC617": { name: "VERDE FLUO", code: "A923", color: "D0D375", file: "filati-verde-fluo.png" },
  "FC614": { name: "TURCHESE", code: "A610", color: "007C86", file: "filati-turchese.png" },
  "FC0020": { name: "VIOLA", code: "S7377", color: "860083", file: "filati-viola.png" },

  // ---------
  // Embossing
  // ---------
  // "no_embossing","01" ,"02" ,"03" ,"04" ,"05" ,"06" ,"07" ,"08" ,"09" ,"10"
  
  "no_embossing": { code: "00", name: "NESSUNO", color: "", slug: "", file: "embossed-nessuno.png" },
  "01": { code: "01", name: "PIED DE POULE", color: "", slug: "pied_de_poule", file: "embossed-pied-de-poule.png" },
  "02": { code: "02", name: "DRONE", color: "", slug: "drone", file: "embossed-drone.png" },
  "03": { code: "03", name: "PLAIT", color: "", slug: "plait", file: "embossed-plait.png" },
  "04": { code: "04", name: "ATOM", color: "", slug: "atom", file: "embossed-atom.png" },
  "05": { code: "05", name: "ASPIDE", color: "", slug: "aspide", file: "embossed-aspide.png" },
  "06": { code: "06", name: "GECKO", color: "", slug: "gecko", file: "embossed-gecko.png" },
  "07": { code: "07", name: "CORTECCIA", color: "", slug: "corteccia", file: "embossed-corteccia.png" },
  "08": { code: "08", name: "CANESTRINO", color: "", slug: "canestrino", file: "embossed-canestrino.png" },
  "09": { code: "09", name: "RUFFLE", color: "", slug: "ruffle", file: "embossed-ruffle.png" },
  "10": { code: "10", name: "KATANA", color: "", slug: "katana", file: "embossed-katana.png" },
  
  // ---------
  // Embossing Super
  // ---------
  // "no_embossing_super","S01" ,"S02" ,"S03" ,"S04" ,"S05" ,"S06" ,"S07" ,"S08" ,"S09" ,"S10"

  "no_embossing_super": { code: "00", name: "NESSUNO", color: "", slug: "", file: "embossed-nessuno.png" },
  "S01": { code: "01", name: "PIED DE POULE", color: "", slug: "pieddepoule", file: "embossed-pied-de-poule.png" },
  "S02": { code: "02", name: "DRONE", color: "", slug: "drone", file: "embossed-drone.png" },
  "S03": { code: "03", name: "PLAIT", color: "", slug: "plait", file: "embossed-plait.png" },
  "S04": { code: "04", name: "ATOM", color: "", slug: "atom", file: "embossed-atom.png" },
  "S05": { code: "05", name: "ASPIDE", color: "", slug: "aspide", file: "embossed-aspide.png" },
  "S06": { code: "06", name: "GECKO", color: "", slug: "gecko", file: "embossed-gecko.png" },
  "S07": { code: "07", name: "CORTECCIA", color: "", slug: "corteccia", file: "embossed-corteccia.png" },
  "S08": { code: "08", name: "CANESTRINO", color: "", slug: "canestrino", file: "embossed-canestrino.png" },
  "S09": { code: "09", name: "RUFFLE", color: "", slug: "ruffle", file: "embossed-ruffle.png" },
  "S10": { code: "10", name: "KATANA", color: "", slug: "katana", file: "embossed-katana.png" },

  // ------
  // Design
  // ------
  // "no_design","D0107","D00001","D00002","D0104","D0103","D00005","D0102","D0105","D0108","D00010","D0101","D0001",

  "no_design": { code: "", name: "NESSUNO", slug: "", color: "", file: "design-nessuno.png" },
  "D0107": { code: "D0107", name: "CUBE", slug: "cube", color: "", file: "design-cube.png" },
  "D00001": { code: "D00001", name: "HIGHWAY", slug: "highway", color: "", file: "design-highway.png" },
  "D00002": { code: "D00002", name: "HIGHWAY V", slug: "highway_v", color: "", file: "design-highway-v.png" },
  "D0104": { code: "D0104", name: "DIAMOND", slug: "diamond", color: "", file: "design-diamond.png" },
  "D0103": { code: "D0103", name: "GOTHIC", slug: "gothic", color: "", file: "design-gothic.png" },
  "D00005": { code: "D00005", name: "MONTECARLO", slug: "montecarlo", color: "", file: "design-montecarlo.png" },
  "D0102": { code: "D0102", name: "TRON", slug: "tron", color: "", file: "design-tron.png" },
  "D0105": { code: "D0105", name: "DOUBLE CUBE", slug: "doublecube", color: "", file: "design-double-cube.png" },
  "D0108": { code: "D0108", name: "HIVE", slug: "hive", color: "", file: "design-hive.png" },
  "D00010": { code: "D00010", name: "RS", slug: "rs", color: "", file: "design-rs.png" },
  "D0101": { code: "D0101", name: "SUNRISE", slug: "sunrise", color: "", file: "design-sunrise.png" },
  "D0001": { code: "D0001", name: "DAYTONA", slug: "daytona", color: "", file: "design-daytona.png" },

  // -----------
  // Traforature
  // -----------
  //  "no_perf","P01"

  "no_perf": { code: "", name: "NO", color: "", slug: "", file: "traforature-no.png" },
  "P01": { code: "", name: "SI", color: "", slug: "forato", file: "traforature-si.png" },
};

