import { ab } from "./ab";
import { cd } from "./cd";
import { ef } from "./ef";
import { supercar } from "./super";

export const series = {
  ab: ab,
  cd: cd,
  ef: ef,
  super: supercar,
};

series.seriesArray = [];

var seriesIndex = 0;
var productIndex = 0;
for (var Skey in series) {
  var S = series[Skey];
  S.code = seriesIndex;

  S.id = Skey;
  S.slug = Skey;

  S.productArray = [];

  if (S.id !== "seriesArray") {
    for (var productId in S.products) {
      var P = S.products[productId];

      P.code = productIndex;

      P.id = productId;
      P.slug = productId;

      S.productArray.push(P);

      ++productIndex;
    }

    series.seriesArray.push(S);

    ++seriesIndex;
  }
}

var series_product_array = [];
for (var seriesIndex in series.seriesArray) {
  var S = series.seriesArray[seriesIndex];
  for (var productIndex in S.productArray) {
    var P = S.productArray[productIndex];
    var SP = {
      series: S,
      product: P,
    };

    series_product_array.push(SP);
  }
}
series.series_product_array = series_product_array;
