import { config } from "../data";

export const Button = ({ m, info, handleClick }) => {
    switch (info.type) {
        case "embossed":
            return (<li
                className="list-inline-item d-flex justify-content-center mb-4"
            >
                <a
                    className="text-center text-decoration-none text-inherit custom-clickable-link"
                    onClick={(e) => {
                        e.preventDefault();
                        handleClick(m, info);
                    }}
                >
                    <span className={"embossed-" + + config.materials[m].slug} style={{ backgroundImage: `url(/images/${info.type}/${config.materials[m].file})` }}></span>
                    <p className="mb-0 text-truncate"> {config.materials[m].name}</p>
                </a>
            </li>)
        case "filati":
            return (<li
                className="list-inline-item flex-fill d-flex justify-content-center ripples"
                onClick={(e) => {
                    e.preventDefault();
                    handleClick(m, info);
                }}
            >
                <a className="text-center text-decoration-none text-inherit w-100 custom-clickable-link">
                    <span className={"filati-" + config.materials[m].code} style={{ backgroundImage: `url(/images/${info.type}/${config.materials[m].file})` }}></span>
                    <h4 className={`mb-0 ${m.search("no_filati") > -1 ? "invisible" : ""}`}>{m}</h4>
                    <p className="text-truncate">{config.materials[m].name}</p>
                </a>
            </li>)
        case "traforature":
            return (<div
                className="me-2 flex-grow-1 custom-clickable-link col-4"
                onClick={(e) => {
                    e.preventDefault();
                    handleClick(m, info);
                }}
            >
                <a className={`btn btn-present d-flex justify-content-center `}>
                    {config.materials[m].name}
                </a>
                <div className="accordian-seprater"></div>
                <div className={"traforature-" + config.materials[m].slug}>
                    <img src={`/images/${info.type}/${config.materials[m].file}`} className="w-100 img-fluid" />
                </div>
            </div>)
        case "design":
            return (<li
                className="list-inline-item d-flex justify-content-center mb-4"
            ><a
                onClick={(e) => {
                    e.preventDefault();
                    handleClick(m, info);
                }}
                className="text-center text-decoration-none text-inherit custom-clickable-link"

            >
                    <span className={"design-" + config.materials[m].slug} style={{ backgroundImage: `url(/images/${info.type}/${config.materials[m].file})` }}></span>
                    <p className="mb-0 text-truncate"> {config.materials[m].name}</p>
                </a></li>)
        case "color":
        default:
            return (<li className="col-4"><a
                onClick={(e) => {
                    e.preventDefault();
                    handleClick(m, info);
                }}
                className="text-nowrap d-inline-flex align-items-center text-decoration-none text-inherit w-100 custom-clickable-link"
            >
                <span
                    className={"color-" + config.materials[m].code}
                    style={config.materials[m].file ?
                        { backgroundImage: `url(/images/${info.type}/${config.materials[m].file})`, backgroundSize: `cover` } :
                        { backgroundColor: "#" + config.materials[m].color }
                    }
                ></span>
                <p className="mb-0 text-truncate">
                    {config.materials[m].name} - {config.materials[m].code ? <span className="fw-bold">{config.materials[m].code}</span> : <></>}
                </p>
            </a>
            </li >)

    }
}
export const MaterialSelector = ({ info, children }) => {
    switch (info.type) {
        case "embossed":
            return (<div className="embossed-pattern d-flex flex-wrap">
                <ul className="mb-0 d-flex flex-wrap list-inline custom-ul-wrapper-1 col-12">
                    {children}
                </ul>
            </div>)
        case "filati":
            return (<div className="filati-wrapper border border-secondary p-3 mb-3">
                <ul className="list-inline align-content-stretch flex-wrap d-flex mb-0 col-12">
                    {children}
                </ul>
            </div>)
        case "traforature":
            return (<ul className="list-group list-group-flush">
                <div className="accordion-body mt-2 mb-3">
                    <div className="d-flex justify-content-center align-items-center col-12">
                        {children}
                    </div>
                </div>
            </ul>)
        case "design":
            return (<div className="design-pattern d-flex flex-wrap">
                <ul className="mb-0 d-flex flex-wrap list-inline custom-ul-wrapper col-12">
                    {children}
                </ul>
            </div>)
        case "color":
        default:
            return (<div className="color-palate d-flex flex-wrap">
                <ul className="mb-0 d-flex flex-wrap list-inline custom-ul-wrapper col-12">
                    {children}
                </ul>
            </div>)
    }
}