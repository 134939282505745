import Accordion from "react-bootstrap/Accordion";
import { config } from "../data";
import { useParams } from "react-router-dom";
import { Button, MaterialSelector } from "./LeftDrawerSelector";



export default function LeftDrawer({ handleClick, enableAccordion }) {
    const { lang, seriesId, productId } = useParams();
    return <aside id="offcanvaSidebar">
    <button className="btn btn-danger closeOffcanvas" onClick={() => {
        document.getElementById("offcanvaSidebar").style.width = "0px";
        document.getElementById("applicaWrapper").style.position = "static";
      }}> CHIUDI X</button>
        <Accordion flush id="myCollapse" style={enableAccordion}>
            {config.series[seriesId].products[productId].mainCat.map((mC, mCIndex) => {
                return (<Accordion.Item eventKey={mCIndex} key={mCIndex} >
                    <Accordion.Header >
                        {mC.name}
                    </Accordion.Header>
                    <Accordion.Body>
                        {mC.subCat ? (<Accordion>
                            {mC.subCat.map((sC, sCIndex) => {
                                return (<Accordion.Item eventKey={sCIndex} key={sCIndex}>
                                    <Accordion.Header as="h3">
                                        {sC.name}
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        {sC.cat ? (<Accordion>
                                            {sC.cat.map((c, cIndex) => {
                                                return (<Accordion.Item eventKey={cIndex} key={cIndex}>
                                                    <Accordion.Header as="h4">
                                                        {c.name}
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        {c.materials ? (
                                                            <MaterialSelector info={c}>
                                                                {c.materials.map((m, mIndex) => {
                                                                    return (<Button m={m} key={mIndex} info={c} handleClick={handleClick} />)
                                                                })}
                                                            </MaterialSelector>
                                                        ) : <></>}


                                                    </Accordion.Body>
                                                </Accordion.Item>)
                                            })}
                                        </Accordion>) : <></>}
                                        {sC.materials ? (<MaterialSelector info={sC}>
                                            {sC.materials.map((m, mIndex) => {
                                                return (<Button m={m} key={mIndex} info={sC} handleClick={handleClick} />)
                                            })}
                                        </MaterialSelector>) : <></>}
                                    </Accordion.Body>
                                </Accordion.Item>)
                            })}
                        </Accordion>) : <></>}
                    </Accordion.Body>

                </Accordion.Item>)
            })}
        </Accordion>
        <div id="applicaWrapper" className="applicaScelteWrapper">
            <button className="btn btn-primary closeOffcanvas" onClick={() => {
                document.getElementById("offcanvaSidebar").style.width = "0px";
                document.getElementById("applicaWrapper").style.position = "static";
              }}> APPLICA SCELTE</button>
        </div>
    </aside>
}