import { useParams } from "react-router";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { config } from "../data";
import Instructions from "../components/Instructions";

import "../scss/main.scss";
import "../styles/developer.scss";

export default function Series() {
  let { lang } = useParams()

  const [instructions, setInstructions] = useState(false);

  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <>
    {instructions && <Instructions onClose={() =>{setInstructions(false)}}/>}
    {/* <div style={{ display: instructions ? 'none' : '' }}> */}
      <main style={{ display: instructions ? 'none' : '' }}>
        <section className="configuration d-flex flex-column justify-content-between align-items-center">
          <div className="entry-title text-center mt-5">
            <h2 className="mb-3">INIZIA LA TUA CONFIGURAZIONE</h2>
            <p>Selezionare la tipologia di interni che si desidera personalizzare.</p>
          </div>
          <div className="entry-segments w-100">
            <div className="row g-5">
              {config.series.series_product_array.map((SP, index) => {
                return (
                  <div className="col-sm-3" key={index}>
                    <Link
                      to={`/${lang}/${SP.series.slug}/${SP.product.slug}/config`}
                      className="text-decoration-none text-inherit d-flex flex-column car-block justify-content-center"
                    >
                      <div className="entry-thumb d-flex justify-content-center align-items-center">
                        <img src={SP.product.thumbBlack} alt="" className="img-fluid" />
                      </div>
                      <div className="entry-content text-center mt-3">
                        <h3>{SP.series.name}</h3>
                        <p>{SP.product.name}</p>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="entry-btn mb-5">
            {/* <a  className="btn btn-primary text-uppercase fs-sm-20">
              Continua
            </a> */}
          </div>
        </section>
      </main>
      <footer style={{ display: instructions ? 'none' : '' }}>
        <div className="social-media d-flex align-items-center">
            <a className="font-bold mb-0 text-white"  target="_blank" href="https://www.medicistyle.com/">medicistyle.atelier</a>
            <ul className="list-inline ml-xs-2 mb-0 d-flex">
            <li className="list-inline-item d-flex align-items-center">
              <a target="_blank" href="https://www.facebook.com/mediciinterniperauto" className="social-facebook" >
              </a>
            </li>
            <li className="list-inline-item d-flex align-items-center">
              <a target="_blank" className="social-instagram" href="https://www.instagram.com/medicistyleatelier/" ></a>
            </li>
            <li className="list-inline-item d-flex align-items-center">
              <a target="_blank" className="social-linkedin" href="https://www.linkedin.com/company/medici-style-srl/" ></a>
            </li>
          </ul>
        </div>
        <div className="entry-footer d-md-flex flex-grow-1 align-items-center">
          <p className="font-bold mb-md-0">
            info@medicistyle.it</p>
          <div className="btn-group ms-auto">
            {/* <Link className="btn btn-primary fs-sm-15 text-uppercase mx-1" to={`/${lang}/collections`}>
              Istruzioni
            </Link> */}
            <button className="btn btn-primary fs-sm-15 text-uppercase mx-1" onClick={() =>{setInstructions(true); console.log("istruzioni")}}>
              Istruzioni
            </button>
          </div>
        </div>
      </footer>
    {/* </div> */}
    
    
    </>
    
  );
}
