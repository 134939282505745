export const supercar = {
    name: "SUPERCAR",
    products: [
        {
            name: "LUX",
            slug: "0",
            code: 0,
            hasThumb: true,
            thumbBlack: "/images/super/car-black.png",
            thumbWhite: "/images/super/car-white.png",
            buildObject: {
                loaderUrl: "/Ferrari_F8/Build/Ferrari_F8.loader.js",
                dataUrl: "/Ferrari_F8/Build/Ferrari_F8.data.unityweb",
                frameworkUrl: "/Ferrari_F8/Build/Ferrari_F8.framework.js.unityweb",
                codeUrl: "/Ferrari_F8/Build/Ferrari_F8.wasm.unityweb",
                streamingAssetsUrl: "/Ferrari_F8/StreamingAssets",
                webglContextAttributes: {
                    preserveDrawingBuffer: true,
                },
            },
            hasModel: true,
            position: [0, 0, 0],
            rotation: [0, 0, 0],
            scale: [1, 1, 1],
            camera: {
                minDistance: 0.1,
                maxDistance: 1.0
            },
            cameraPositions: [
                { type: "cam_01", tx: 0.108000003, ty: 0.92900002, tz: 0.100000001, rx: 34.808754, ry: 198.162613, rz: 357.601349 },
                { type: "cam_sedile", tx: 0.112999998, ty: 0.662999988, tz: -0.039000001, rx: 55.5118027, ry: 206.871918, rz: 334.494171 },
                { type: "cam_sedile_esterno", tx: 0.004, ty: 0.603999972, tz: -0.050999999, rx: 69.4790726, ry: 213.660965, rz: 347.037354 },
                { type: "cam_sedile_dettaglio_01", tx: -0.027000001, ty: 0.460999995, tz: -0.275999993, rx: 69.4790726, ry: 213.66098, rz: 7.23011827 },
                { type: "cam_sedile_dettaglio_02", tx: -0.057999998, ty: 0.409999996, tz: -0.088, rx: 69.4790726, ry: 213.66098, rz: 7.23011827 },
                { type: "cam_sedile_dettaglio_03", tx: -0.041000001, ty: 0.501999974, tz: -0.486000001, rx: 69.4790726, ry: 213.66098, rz: 7.23011827 },
                { type: "cam_volante", tx: -0.048999999, ty: 0.846000016, tz: -0.296000004, rx: 25.5547218, ry: 250.779419, rz: 357.817139 },
                { type: "cam_portiere", tx: 0.041999999, ty: 0.870999992, tz: -0.493999988, rx: 41.6738091, ry: 189.84584, rz: 359.086823 },
                { type: "cam_insieme_01", tx: 0.326000005, ty: 0.85799998, tz: -0.004, rx: 28.4795609, ry: 228.487274, rz: 356.161041 },
                { type: "cam_insieme_02", tx: 0.241999999, ty: 0.838, tz: 0.203999996, rx: 27.2362442, ry: 216.942947, rz: 355.093964 },
                { type: "cam_insieme_03", tx: 0.241999999, ty: 0.838, tz: 0.203999996, rx: 33.1726646, ry: 248.451782, rz: 354.400085 },
                { type: "initialStateCamera", tx: -0.64200002, ty: 0.921999991, tz: 0.045000002, rx: 23.6690903, ry: 94.3879471, rz: 0.991911888 }
            ],
            onScreenCameraPositions: [
                { type: "cam_01", tx: -0.593, ty: 0.914, tz: 0, rx: 22.298, ry: 90, rz: 0 },
                { type: "cam_02", tx: -0.008, ty: 0.793, tz: -0.417, rx: 17.87, ry: -75.232, rz: 0 },
                { type: "cam_03", tx: 0.0150006, ty: 0.83, tz: -0.166, rx: 23.292, ry: -174.622, rz: -3.193 },
                { type: "cam_04", tx: 0.601, ty: 0.9609, tz: 0.005, rx: 20.743, ry: -90, rz: 0 }
            ],
            mainCat: [
                {
                    name: "SEDILE",
                    subCat: [
                        {
                            name: "CENTRALE SEDILE",
                            cat: [
                                {
                                    name: "ALCANTARA",
                                    type: "color",
                                    slug: "alcantara",
                                    mesh: "centrale_sedile",
                                    history: "CENTRALE SEDILE",
                                    historySlug: "centrale_sedile",
                                    catHistorySlug: "sedile",
                                    materials: ["AL01", "AL02", "AL03", "AL04", "AL05", "AL06", "AL07", "AL08", "AL09", "AL010", "AL011", "AL012", "AL013", "AL014", "AL015"]
                                },
                                {
                                    name: "PELLE NAPPA",
                                    type: "color",
                                    slug: "nappa",
                                    mesh: "centrale_sedile",
                                    history: "CENTRALE SEDILE",
                                    historySlug: "centrale_sedile",
                                    catHistorySlug: "sedile",
                                    materials: ["1215", "1241", "1236", "1207", "1240", "1227", "1221", "1239", "1225", "1237", "1223", "1200", "1228", "1243", "1238", "1210", "1229", "1232", "1218", "1205", "1234", "1208", "1233",]
                                },
                                {
                                    name: "CENTRALE TRAFORATURE",
                                    type: "traforature",
                                    slug: "forato",
                                    mesh: "centrale_sedile",
                                    history: "CENTRALE TRAFORATURE",
                                    historySlug: "centrale_traforature",
                                    catHistorySlug: "sedile",
                                    materials: ["no_perf", "P01"]
                                }
                            ]
                        },
                        {
                            name: "CENTRALE DESIGN",
                            cat: [
                                {
                                    name: "DESIGN",
                                    type: "design",
                                    slug: "design",
                                    mesh: "centrale_sedile",
                                    history: "CENTRALE DESIGN",
                                    historySlug: "centrale_design",
                                    catHistorySlug: "sedile",
                                    materials: ["no_design", "D0107", "D00001", "D00002", "D0104", "D0103", "D00005", "D0102", "D0105", "D0108", "D00010", "D0101", "D0001"],
                                },
                                {
                                    name: "CENTRALE TRAFORATURE",
                                    type: "traforature",
                                    slug: "forato",
                                    mesh: "centrale_sedile",
                                    history: "CENTRALE TRAFORATURE",
                                    historySlug: "centrale_traforature",
                                    catHistorySlug: "sedile",
                                    materials: ["no_perf", "P01"]
                                }
                            ]
                        },
                        {
                            name: "CENTRALE EMBOSSING",
                            cat: [
                                {
                                    name: "EMBOSSING",
                                    type: "embossed",
                                    slug: "embossing",
                                    mesh: "centrale_embossing",
                                    history: "CENTRALE EMBOSSING",
                                    historySlug: "centrale_embossing",
                                    catHistorySlug: "sedile",
                                    materials: ["no_embossing_super", "S08", "S02", "S06", "S10", "S03", "S09", "S05", "S04", "S07", "S01"],
                                },
                            ]
                        },

                        {
                            name: "ESTERNO SEDILE",
                            cat: [
                                {
                                    name: "ALCANTARA",
                                    type: "color",
                                    slug: "alcantara",
                                    mesh: "esterno_sedile",
                                    history: "ESTERNO SEDILE",
                                    historySlug: "esterno_sedile",
                                    catHistorySlug: "sedile",
                                    material: "esterno_sedile_alcantara",
                                    materials: ["AL01", "AL02", "AL03", "AL04", "AL05", "AL06", "AL07", "AL08", "AL09", "AL010", "AL011", "AL012", "AL013", "AL014", "AL015"]
                                },
                                {
                                    name: "PELLE NAPPA",
                                    type: "color",
                                    slug: "nappa",
                                    mesh: "esterno_sedile",
                                    history: "ESTERNO SEDILE",
                                    historySlug: "esterno_sedile",
                                    catHistorySlug: "sedile",
                                    material: "esterno_sedile_nappa",
                                    materials: ["1215", "1241", "1236", "1207", "1240", "1227", "1221", "1239", "1225", "1237", "1223", "1200", "1228", "1243", "1238", "1210", "1229", "1232", "1218", "1205", "1234", "1208", "1233",],
                                },
                            ]

                        },
                    ]
                },
                {
                    name: "CIELO E MONTANTI",
                    subCat: [
                        {
                            name: "ALCANTARA",
                            type: "color",
                            slug: "alcantara",
                            mesh: "cielo_montanti",
                            history: "CIELO E MONTANTI",
                            historySlug: "cielo_montanti",
                            catHistorySlug: "cielo_montanti",
                            material: "cielo_montanti_alcantara",
                            materials: ["AL01", "AL02", "AL03", "AL04", "AL05", "AL06", "AL07", "AL08", "AL09", "AL010", "AL011", "AL012", "AL013", "AL014", "AL015"],
                        },
                    ]
                },
                {
                    name: "TUNNEL CENTRALE",
                    subCat: [
                        {
                            name: "ALCANTARA",
                            type: "color",
                            slug: "alcantara",
                            mesh: "tunnel_centrale",
                            history: "TUNNEL CENTRALE",
                            historySlug: "tunnel_centrale",
                            catHistorySlug: "tunnel_centrale",
                            material: "tunnel_centrale_alcantara",
                            materials: ["AL01", "AL02", "AL03", "AL04", "AL05", "AL06", "AL07", "AL08", "AL09", "AL010", "AL011", "AL012", "AL013", "AL014", "AL015"],
                        },
                        {
                            name: "PELLE NAPPA",
                            type: "color",
                            slug: "nappa",
                            mesh: "tunnel_centrale",
                            history: "TUNNEL CENTRALE",
                            historySlug: "tunnel_centrale",
                            catHistorySlug: "tunnel_centrale",
                            material: "tunnel_centrale_nappa",
                            materials: ["1215", "1241", "1236", "1207", "1240", "1227", "1221", "1239", "1225", "1237", "1223", "1200", "1228", "1243", "1238", "1210", "1229", "1232", "1218", "1205", "1234", "1208", "1233"],
                        },
                    ]
                },
                {
                    name: "VOLANTE",
                    subCat: [
                        {
                            name: "VOLANTE CENTRALE",
                            cat: [{
                                name: "ALCANTARA",
                                type: "color",
                                slug: "alcantara",
                                mesh: "volante_centrale",
                                history: "VOLANTE CENTRALE",
                                historySlug: "volante_centrale",
                                catHistorySlug: "volante",
                                material: "volante_centrale_alcantara",
                                materials: ["AL01", "AL02", "AL03", "AL04", "AL05", "AL06", "AL07", "AL08", "AL09", "AL010", "AL011", "AL012", "AL013", "AL014", "AL015"],
                            },
                            {
                                name: "PELLE NAPPA",
                                type: "color",
                                slug: "nappa",
                                mesh: "volante_centrale",
                                history: "VOLANTE CENTRALE",
                                historySlug: "volante_centrale",
                                catHistorySlug: "volante",
                                material: "volante_centrale_nappa",
                                materials: ["1215", "1241", "1236", "1207", "1240", "1227", "1221", "1239", "1225", "1237", "1223", "1200", "1228", "1243", "1238", "1210", "1229", "1232", "1218", "1205", "1234", "1208", "1233"],
                            },]
                        },
                        {
                            name: "VOLANTE LATERALE",
                            cat: [{
                                name: "ALCANTARA",
                                type: "color",
                                slug: "alcantara",
                                mesh: "volante_laterale",
                                history: "VOLANTE LATERALE",
                                historySlug: "volante_laterale",
                                catHistorySlug: "volante",
                                material: "volante_laterale_alcantara",
                                materials: ["AL01", "AL02", "AL03", "AL04", "AL05", "AL06", "AL07", "AL08", "AL09", "AL010", "AL011", "AL012", "AL013", "AL014", "AL015"],
                            },
                            {
                                name: "PELLE NAPPA",
                                type: "color",
                                slug: "nappa",
                                mesh: "volante_laterale",
                                history: "VOLANTE LATERALE",
                                historySlug: "volante_laterale",
                                catHistorySlug: "volante",
                                material: "volante_laterale_nappa",
                                materials: ["1215", "1241", "1236", "1207", "1240", "1227", "1221", "1239", "1225", "1237", "1223", "1200", "1228", "1243", "1238", "1210", "1229", "1232", "1218", "1205", "1234", "1208", "1233"],
                            },]
                        },
                        {
                            name: "PAGNOTTA AIRBAG",
                            cat: [{
                                name: "ALCANTARA",
                                type: "color",
                                slug: "alcantara",
                                mesh: "pagnotta",
                                history: "PAGNOTTA AIRBAG",
                                historySlug: "pagnotta",
                                catHistorySlug: "volante",
                                material: "pagnotta_alcantara",
                                materials: ["AL01", "AL02", "AL03", "AL04", "AL05", "AL06", "AL07", "AL08", "AL09", "AL010", "AL011", "AL012", "AL013", "AL014", "AL015"],
                            },
                            {
                                name: "PELLE NAPPA",
                                type: "color",
                                slug: "nappa",
                                mesh: "pagnotta",
                                history: "PAGNOTTA AIRBAG",
                                historySlug: "pagnotta",
                                catHistorySlug: "volante",
                                material: "pagnotta_nappa",
                                materials: ["1215", "1241", "1236", "1207", "1240", "1227", "1221", "1239", "1225", "1237", "1223", "1200", "1228", "1243", "1238", "1210", "1229", "1232", "1218", "1205", "1234", "1208", "1233"],
                            },]
                        },
                    ]
                },
                {
                    name: "CRUSCOTTO",
                    subCat: [
                        {
                            name: "CRUSCOTTO SUPERIORE",
                            cat: [{
                                name: "ALCANTARA",
                                type: "color",
                                slug: "alcantara",
                                mesh: "cruscotto_superiore",
                                history: "CRUSCOTTO SUPERIORE",
                                historySlug: "cruscotto_superiore",
                                catHistorySlug: "cruscotto",
                                material: "cruscotto_superiore_alcantara",
                                materials: ["AL01", "AL02", "AL03", "AL04", "AL05", "AL06", "AL07", "AL08", "AL09", "AL010", "AL011", "AL012", "AL013", "AL014", "AL015"],
                            },
                            {
                                name: "PELLE NAPPA",
                                type: "color",
                                slug: "nappa",
                                mesh: "cruscotto_superiore",
                                history: "CRUSCOTTO SUPERIORE",
                                historySlug: "cruscotto_superiore",
                                catHistorySlug: "cruscotto",
                                material: "cruscotto_superiore_nappa",
                                materials: ["1215", "1241", "1236", "1207", "1240", "1227", "1221", "1239", "1225", "1237", "1223", "1200", "1228", "1243", "1238", "1210", "1229", "1232", "1218", "1205", "1234", "1208", "1233"],
                            },]
                        },
                        {
                            name: "CRUSCOTTO INFERIORE",
                            cat: [{
                                name: "ALCANTARA",
                                type: "color",
                                slug: "alcantara",
                                mesh: "cruscotto_inferiore",
                                history: "CRUSCOTTO INFERIORE",
                                historySlug: "cruscotto_inferiore",
                                catHistorySlug: "cruscotto",
                                material: "cruscotto_inferiore_alcantara",
                                materials: ["AL01", "AL02", "AL03", "AL04", "AL05", "AL06", "AL07", "AL08", "AL09", "AL010", "AL011", "AL012", "AL013", "AL014", "AL015"],
                            },
                            {
                                name: "PELLE NAPPA",
                                type: "color",
                                slug: "nappa",
                                mesh: "cruscotto_inferiore",
                                history: "CRUSCOTTO INFERIORE",
                                historySlug: "cruscotto_inferiore",
                                catHistorySlug: "cruscotto",
                                material: "cruscotto_inferiore_nappa",
                                materials: ["1215", "1241", "1236", "1207", "1240", "1227", "1221", "1239", "1225", "1237", "1223", "1200", "1228", "1243", "1238", "1210", "1229", "1232", "1218", "1205", "1234", "1208", "1233"],
                            },]
                        },
                    ]
                },
                {
                    name: "PANNELLO PORTA",
                    subCat: [
                        {
                            name: "PANNELLO PORTA SUPERIORE",
                            cat: [{
                                name: "ALCANTARA",
                                type: "color",
                                slug: "alcantara",
                                mesh: "pannello_porta_superiore",
                                history: "PANNELLO PORTA SUPERIORE",
                                historySlug: "pannello_porta_superiore",
                                catHistorySlug: "pannello_porta",
                                material: "pannello_porta_superiore_alcantara",
                                materials: ["AL01", "AL02", "AL03", "AL04", "AL05", "AL06", "AL07", "AL08", "AL09", "AL010", "AL011", "AL012", "AL013", "AL014", "AL015"],
                            },
                            {
                                name: "PELLE NAPPA",
                                type: "color",
                                slug: "nappa",
                                mesh: "pannello_porta_superiore",
                                history: "PANNELLO PORTA SUPERIORE",
                                historySlug: "pannello_porta_superiore",
                                catHistorySlug: "pannello_porta",
                                material: "pannello_porta_superiore_nappa",
                                materials: ["1215", "1241", "1236", "1207", "1240", "1227", "1221", "1239", "1225", "1237", "1223", "1200", "1228", "1243", "1238", "1210", "1229", "1232", "1218", "1205", "1234", "1208", "1233"],
                            },]
                        },
                        {
                            name: "PANNELLO PORTA INFERIORE",
                            cat: [{
                                name: "ALCANTARA",
                                type: "color",
                                slug: "alcantara",
                                mesh: "pannello_porta_inferiore",
                                history: "PANNELLO PORTA INFERIORE",
                                historySlug: "pannello_porta_inferiore",
                                catHistorySlug: "pannello_porta",
                                material: "pannello_porta_inferiore_alcantara",
                                materials: ["AL01", "AL02", "AL03", "AL04", "AL05", "AL06", "AL07", "AL08", "AL09", "AL010", "AL011", "AL012", "AL013", "AL014", "AL015"],
                            },
                            {
                                name: "PELLE NAPPA",
                                type: "color",
                                slug: "nappa",
                                mesh: "pannello_porta_inferiore",
                                history: "PANNELLO PORTA INFERIORE",
                                historySlug: "pannello_porta_inferiore",
                                catHistorySlug: "pannello_porta",
                                material: "pannello_porta_inferiore_nappa",
                                materials: ["1215", "1241", "1236", "1207", "1240", "1227", "1221", "1239", "1225", "1237", "1223", "1200", "1228", "1243", "1238", "1210", "1229", "1232", "1218", "1205", "1234", "1208", "1233"],
                            },]
                        },
                    ]
                },
                {
                    name: "FILATI",
                    subCat: [
                        {
                            name: "FILATI SEDILE",
                            type: "filati",
                            slug: "filati_sedile",
                            mesh: "esterno_sedile_filati",
                            history: "FILATI SEDILE",
                            historySlug: "filati_sedile",
                            catHistorySlug: "filati",
                            onMaterial: "filati_sedile",
                            offMaterial: "filati_sedile_off",
                            materials: ['no_filati_sedile', 'FS10', 'FS520', 'FS513', 'FS3', 'FS92', 'FS830', 'FS415', 'FS822', 'FS717', 'FS775', 'FS573', 'FS308', 'FS0010', 'FS323', 'FS406', 'FS407', 'FS932', 'FS0019', 'FS0017', 'FS617', 'FS614', 'FS0020'],
                        },
                        {
                            name: "FILATI DESIGN",
                            type: "filati",
                            slug: "filati_design",
                            mesh: "filati_design",
                            history: "FILATI DESIGN",
                            historySlug: "filati_design",
                            catHistorySlug: "filati",
                            onMaterial: "filati_design",
                            offMaterial: "filati_design_off",
                            materials: ['no_filati_design', 'FD10', 'FD520', 'FD513', 'FD3', 'FD92', 'FD830', 'FD415', 'FD822', 'FD717', 'FD775', 'FD573', 'FD308', 'FD0010', 'FD323', 'FD406', 'FD407', 'FD932', 'FD0019', 'FD0017', 'FD617', 'FD614', 'FD0020'],
                        },
                        {
                            name: "FILATI VOLANTE",
                            type: "filati",
                            slug: "filati_volante",
                            mesh: "volante_filati",
                            history: "FILATI VOLANTE",
                            historySlug: "filati_volante",
                            catHistorySlug: "filati",
                            onMaterial: "filati_volante",
                            offMaterial: "filati_volante_off",
                            materials: ['no_filati_volante', "PVP10", "PVA520", "PVA513", "PVP3", "PVP92", "PVA830", "PVA415", "PVA822", "PVA717", "PVP775", "PVP573", "PVA308", "PVA0010", "PVA323", "PVA406", "PVP407", "PVA932", "PVA0019", "PVA0017", "PVA617", "PVA614", "PVA0020"],
                        },
                        {
                            name: "FILATI PANNELLI PORTA",
                            type: "filati",
                            slug: "filati_parti_rigide",
                            mesh: "pannello_porta_filati",
                            history: "FILATI PANNELLI PORTA",
                            historySlug: "filati_parti_rigide",
                            catHistorySlug: "filati",
                            onMaterial: "filati_parti_rigide",
                            offMaterial: "filati_parti_rigide_off",
                            materials: ['no_filati_parti_rigide', 'FR10', 'FR520', 'FR513', 'FR3', 'FR92', 'FR830', 'FR415', 'FR822', 'FR717', 'FR775', 'FR573', 'FR308', 'FR0010', 'FR323', 'FR406', 'FR407', 'FR932', 'FR0019', 'FR0017', 'FR617', 'FR614', 'FR0020'],
                        },
                        {
                            name: "FILATI TUNNEL CENTRALE",
                            type: "filati",
                            slug: "filati_tunnel_centrale",
                            mesh: "tunnel_centrale_filati",
                            history: "FILATI TUNNEL CENTRALE",
                            historySlug: "filati_tunnel_centrale",
                            catHistorySlug: "filati",
                            onMaterial: "filati_tunnel_centrale",
                            offMaterial: "filati_tunnel_centrale_off",
                            materials: ['no_filati_tunnel_centrale', 'FT10', 'FT520', 'FT513', 'FT3', 'FT92', 'FT830', 'FT415', 'FT822', 'FT717', 'FT775', 'FT573', 'FT308', 'FT0010', 'FT323', 'FT406', 'FT407', 'FT932', 'FT0019', 'FT0017', 'FT617', 'FT614', 'FT0020'],
                        },
                        {
                            name: "FILATI CRUSCOTTO",
                            type: "filati",
                            slug: "filati_cruscotto",
                            mesh: "cruscotto_filati",
                            history: "FILATI CRUSCOTTO",
                            historySlug: "filati_cruscotto",
                            catHistorySlug: "filati",
                            onMaterial: "filati_cruscotto",
                            offMaterial: "filati_cruscotto_off",
                            materials: ['no_filati_cruscotto', 'FC10', 'FC520', 'FC513', 'FC3', 'FC92', 'FC830', 'FC415', 'FC822', 'FC717', 'FC775', 'FC573', 'FC308', 'FC0010', 'FC323', 'FC406', 'FC407', 'FC932', 'FC0019', 'FC0017', 'FC617', 'FC614', 'FC0020'],
                        },
                    ],
                },
            ],
            historyArray: [
                'centrale_sedile',
                'centrale_design',
                'centrale_traforature',
                'centrale_embossing',
                'esterno_sedile',
                'cielo_montanti',
                'tunnel_centrale',
                'volante_centrale',
                'volante_laterale',
                'pagnotta',
                'cruscotto_superiore',
                'cruscotto_inferiore',
                'pannello_porta_superiore',
                'pannello_porta_inferiore',
                'filati_sedile',
                'filati_design',
                'filati_volante',
                'filati_parti_rigide',
                'filati_tunnel_centrale',
                'filati_cruscotto'
            ],
            materialHistoryArray: [
                'centrale_sedile',
                'centrale_embossing',
                'esterno_sedile',
                'cielo_montanti',
                'tunnel_centrale',
                'volante_centrale',
                'volante_laterale',
                'pagnotta',
                'cruscotto_superiore',
                'cruscotto_inferiore',
                'pannello_porta_superiore',
                'pannello_porta_inferiore',
                'filati_sedile',
                'filati_volante',
                'filati_parti_rigide',
                'filati_tunnel_centrale',
                'filati_cruscotto',
            ],
            catHistoryArray: [
                { name: "SEDILE", code: "sedile" ,mandatory : true },
                { name: "CIELO E MONTANTI", code: "cielo_montanti" },
                { name: "TUNNEL CENTRALE", code: "tunnel_centrale", mandatory : true  },
                { name: "VOLANTE", code: "volante" },
                { name: "CRUSCOTTO", code: "cruscotto" },
                { name: "PANNELLO PORTA", code: "pannello_porta" , mandatory : true },
                { name: "FILATI", code: "filati" },
            ],
            catHistoryMap: {
                sedile: false,
                cielo_montanti: false,
                tunnel_centrale: false,
                volante: false,
                cruscotto: false,
                pannello_porta: false,
                filati: false
            },
            allowedMatForEmbossing: ["1215", "1241", "1236", "1207", "1240", "1227", "1221", "1239", "1225", "1237", "1223", "1200", "1228", "1243", "1238", "1210", "1229", "1232", "1218", "1205", "1234", "1208", "1233"]
        },
    ]
}