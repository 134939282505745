export const cd = {
    name: "SEGMENTI C / D",
    products: [
        {
            name: "FAMILY CAR",
            slug: "0",
            code: 0,
            hasThumb: true,
            thumbBlack: "/images/cd/car-black.png",
            thumbWhite: "/images/cd/car-white.png",
            buildObject: {
                loaderUrl: "/Rav_4/Build/Rav_4.loader.js",
                dataUrl: "/Rav_4/Build/Rav_4.data.unityweb",
                frameworkUrl: "/Rav_4/Build/Rav_4.framework.js.unityweb",
                codeUrl: "/Rav_4/Build/Rav_4.wasm.unityweb",
                streamingAssetsUrl: "/Rav_4/StreamingAssets",
                webglContextAttributes: {
                    preserveDrawingBuffer: true,
                },
            },
            hasModel: true,
            position: [0, 0, 0],
            rotation: [0, 0, 0],
            scale: [1, 1, 1],
            camera: {
                minDistance: 0.1,
                maxDistance: 1.0
            },
            cameraPositions: [
                { type: "cam_01", tx: 0.266000003, ty: 1.12199998, tz: 0.186000004, rx: 17.247385, ry: 276.639252, rz: 0.598874152 },
                { type: "cam_sedile", tx: -0.120999999, ty: 1.03799999, tz: -0.025, rx: 56.374649, ry: 299.828156, rz: 346.109955 },
                { type: "cam_sedile_esterno", tx: -0.135000005, ty: 0.916999996, tz: 0.119000003, rx: 74.7887192, ry: 307.420898, rz: 13.8430157 },
                { type: "cam_sedile_dettaglio_01", tx: -0.360000014, ty: 0.958000004, tz: -0.039999999, rx: 70.5570755, ry: 353.498291, rz: 6.87974167 },
                { type: "cam_sedile_dettaglio_02", tx: -0.153999999, ty: 0.855000019, tz: 0.211999997, rx: 69.4790726, ry: 351.001831, rz: 7.23005342 },
                { type: "cam_sedile_dettaglio_03", tx: -0.535000026, ty: 0.880999982, tz: 0.177000001, rx: 69.4790955, ry: 1.19273293, rz: 7.23008394 },
                { type: "cam_volante", tx: -0.298000008, ty: 1.19599998, tz: 0.118000001, rx: 25.5547104, ry: 352.101593, rz: 357.817139 },
                { type: "cam_portiere", tx: -0.395000011, ty: 1.24899995, tz: 0.081, rx: 41.6738091, ry: 282.618347, rz: 359.086823 },
                { type: "cam_insieme_01", tx: 0.079999998, ty: 1.27900004, tz: -0.199000001, rx: 28.4795246, ry: 318.010132, rz: 356.161072 },
                { type: "cam_insieme_02", tx: 0.159999996, ty: 1.36300004, tz: -0.344999999, rx: 33.4162979, ry: 340.482086, rz: 357.926483 },
                { type: "cam_insieme_03", tx: 0.012, ty: 1.27600002, tz: -0.270999998, rx: 25.318119, ry: 359.114868, rz: 359.784332 },
                { type: "initialStateCamera", tx: 0, ty: 1.34599996, tz: 0.768999994, rx: 22.018034, ry: 180, rz: 0 }
            ],
            onScreenCameraPositions: [
                { type: "cam_01", tx: 0, ty: 1.298, tz: 0.614, rx: 18.323, ry: 180, rz: 0 },
                { type: "cam_02", tx: -0.354, ty: 1.19, tz: 0.127, rx: 25.555, ry: 0, rz: 0 },
                { type: "cam_03", tx: -0.267, ty: 1.128, tz: 0.155, rx: 25.555, ry: -80.459, rz: -2.183 },
                { type: "cam_04", tx: -0.001, ty: 1.481, tz: -1.302, rx: 24.655, ry: 0, rz: 0 }
            ],
            mainCat: [
                {
                    name: "SEDILE",
                    subCat: [
                        {
                            name: "CENTRALE SEDILE",
                            cat: [
                                {
                                    name: "ALCANTARA",
                                    type: "color",
                                    slug: "alcantara",
                                    mesh: "centrale_sedile",
                                    history: "CENTRALE SEDILE",
                                    historySlug: "centrale_sedile",
                                    catHistorySlug: "sedile",
                                    materials: ["AL01", "AL02", "AL03", "AL04", "AL05", "AL06", "AL07", "AL08", "AL09", "AL010", "AL011", "AL012", "AL013", "AL014", "AL015"]
                                },
                                {
                                    name: "PELLE NAPPA",
                                    type: "color",
                                    slug: "nappa",
                                    mesh: "centrale_sedile",
                                    history: "CENTRALE SEDILE",
                                    historySlug: "centrale_sedile",
                                    catHistorySlug: "sedile",
                                    materials: ["1215", "1241", "1236", "1207", "1240", "1227", "1221", "1239", "1225", "1237", "1223", "1200", "1228", "1243", "1238", "1210", "1229", "1232", "1218", "1205", "1234", "1208", "1233",]
                                },
                                {
                                    name: "PELLE STAMPA",
                                    type: "color",
                                    slug: "stampa",
                                    mesh: "centrale_sedile",
                                    history: "CENTRALE SEDILE",
                                    historySlug: "centrale_sedile",
                                    catHistorySlug: "sedile",
                                    materials: ["00s", "01s", "02s", "03s", "04s", "05s", "06s", "07s", "08s", "09s", "10s", "11s", "12s", "13s", "14s", "15s", "16s", "17s", "18s"]
                                },
                                {
                                    name: "CENTRALE TRAFORATURE",
                                    type: "traforature",
                                    slug: "forato",
                                    mesh: "centrale_sedile",
                                    history: "CENTRALE TRAFORATURE",
                                    historySlug: "centrale_traforature",
                                    catHistorySlug: "sedile",
                                    materials: ["no_perf", "P01"]
                                }
                            ]
                        },
                        {
                            name: "CENTRALE DESIGN",
                            cat: [
                                {
                                    name: "DESIGN",
                                    type: "design",
                                    slug: "design",
                                    mesh: "centrale_sedile",
                                    history: "CENTRALE DESIGN",
                                    historySlug: "centrale_design",
                                    catHistorySlug: "sedile",
                                    materials: ["no_design", "D0107", "D00001", "D00002", "D0103", "D0104", "D00005", "D0102"],
                                },
                                {
                                    name: "CENTRALE TRAFORATURE",
                                    type: "traforature",
                                    slug: "forato",
                                    mesh: "centrale_sedile",
                                    history: "CENTRALE TRAFORATURE",
                                    historySlug: "centrale_traforature",
                                    catHistorySlug: "sedile",
                                    materials: ["no_perf", "P01"]
                                }
                            ]
                        },

                        {
                            name: "ESTERNO SEDILE",
                            cat: [
                                {
                                    name: "PELLE NAPPA",
                                    type: "color",
                                    slug: "nappa",
                                    mesh: "esterno_sedile",
                                    history: "ESTERNO SEDILE",
                                    historySlug: "esterno_sedile",
                                    catHistorySlug: "sedile",
                                    material: "esterno_sedile_nappa",
                                    materials: ["1215", "1241", "1236", "1207", "1240", "1227", "1221", "1239", "1225", "1237", "1223", "1200", "1228", "1243", "1238", "1210", "1229", "1232", "1218", "1205", "1234", "1208", "1233",],
                                },
                                {
                                    name: "PELLE STAMPA",
                                    type: "color",
                                    slug: "stampa",
                                    mesh: "esterno_sedile",
                                    history: "ESTERNO SEDILE",
                                    historySlug: "esterno_sedile",
                                    catHistorySlug: "sedile",
                                    material: "esterno_sedile_stampa",
                                    materials: ["00s", "01s", "02s", "03s", "04s", "05s", "06s", "07s", "08s", "09s", "10s", "11s", "12s", "13s", "14s", "15s", "16s", "17s", "18s"],
                                },
                            ]

                        },
                    ]
                },
                {
                    name: "VOLANTE",
                    subCat: [
                        {
                            name: "PELLE NAPPA",
                            type: "color",
                            slug: "nappa",
                            mesh: "volante",
                            history: "VOLANTE",
                            historySlug: "volante",
                            catHistorySlug: "volante",
                            material: "volante_nappa",
                            materials: ["1215", "1241", "1236", "1207", "1240", "1227", "1221", "1239", "1225", "1237", "1223", "1200", "1228", "1243", "1238", "1210", "1229", "1232", "1218", "1205", "1234", "1208", "1233"],
                        },
                        {
                            name: "PELLE STAMPA",
                            type: "color",
                            slug: "stampa",
                            mesh: "volante",
                            history: "VOLANTE",
                            historySlug: "volante",
                            catHistorySlug: "volante",
                            material: "volante_stampa",
                            materials: ["00s", "01s", "02s", "03s", "04s", "05s", "06s", "07s", "08s", "09s", "10s", "11s", "12s", "13s", "14s", "15s", "16s", "17s", "18s"],
                        },
                    ]
                },
                {
                    name: "PANNELLO PORTA",
                    subCat: [
                        {
                            name: "ALCANTARA",
                            type: "color",
                            slug: "alcantara",
                            mesh: "pannello_porta",
                            history: "PANNELLO PORTA",
                            historySlug: "pannello_porta",
                            catHistorySlug: "pannello_porta",
                            material: "pannello_porta_alcantara",
                            materials: ["AL01", "AL02", "AL03", "AL04", "AL05", "AL06", "AL07", "AL08", "AL09", "AL010", "AL011", "AL012", "AL013", "AL014", "AL015"],
                        },
                        {
                            name: "PELLE NAPPA",
                            type: "color",
                            slug: "nappa",
                            mesh: "pannello_porta",
                            history: "PANNELLO PORTA",
                            historySlug: "pannello_porta",
                            catHistorySlug: "pannello_porta",
                            material: "pannello_porta_nappa",
                            materials: ["1215", "1241", "1236", "1207", "1240", "1227", "1221", "1239", "1225", "1237", "1223", "1200", "1228", "1243", "1238", "1210", "1229", "1232", "1218", "1205", "1234", "1208", "1233"],
                        },
                        {
                            name: "PELLE STAMPA",
                            type: "color",
                            slug: "stampa",
                            mesh: "pannello_porta",
                            history: "PANNELLO PORTA",
                            historySlug: "pannello_porta",
                            catHistorySlug: "pannello_porta",
                            material: "pannello_porta_stampa",
                            materials: ["00s", "01s", "02s", "03s", "04s", "05s", "06s", "07s", "08s", "09s", "10s", "11s", "12s", "13s", "14s", "15s", "16s", "17s", "18s"],
                        },
                    ]
                },
                {
                    name: "FILATI",
                    subCat: [
                        {
                            name: "FILATI SEDILE",
                            type: "filati",
                            slug: "filati_sedile",
                            mesh: "esterno_sedile_filati",
                            history: "FILATI SEDILE",
                            historySlug: "filati_sedile",
                            catHistorySlug: "filati",
                            onMaterial: "filati_sedile",
                            offMaterial: "filati_sedile_off",
                            materials: ['no_filati_sedile', 'FS10', 'FS520', 'FS513', 'FS3', 'FS92', 'FS830', 'FS415', 'FS822', 'FS717', 'FS775', 'FS573', 'FS308', 'FS0010', 'FS323', 'FS406', 'FS407', 'FS932', 'FS0019', 'FS0017', 'FS617', 'FS614', 'FS0020'],
                        },
                        {
                            name: "FILATI DESIGN",
                            type: "filati",
                            slug: "filati_design",
                            mesh: "filati_design",
                            history: "FILATI DESIGN",
                            historySlug: "filati_design",
                            catHistorySlug: "filati",
                            onMaterial: "filati_design",
                            offMaterial: "filati_design_off",
                            materials: ['no_filati_design', 'FD10', 'FD520', 'FD513', 'FD3', 'FD92', 'FD830', 'FD415', 'FD822', 'FD717', 'FD775', 'FD573', 'FD308', 'FD0010', 'FD323', 'FD406', 'FD407', 'FD932', 'FD0019', 'FD0017', 'FD617', 'FD614', 'FD0020'],
                        },
                        {
                            name: "FILATI VOLANTE",
                            type: "filati",
                            slug: "filati_volante",
                            mesh: "volante_filati",
                            history: "FILATI VOLANTE",
                            historySlug: "filati_volante",
                            catHistorySlug: "filati",
                            onMaterial: "filati_volante",
                            offMaterial: "filati_volante_off",
                            materials: ['no_filati_volante', "PVP10", "PVA520", "PVA513", "PVP3", "PVP92", "PVA830", "PVA415", "PVA822", "PVA717", "PVP775", "PVP573", "PVA308", "PVA0010", "PVA323", "PVA406", "PVP407", "PVA932", "PVA0019", "PVA0017", "PVA617", "PVA614", "PVA0020"],
                        },
                        {
                            name: "FILATI PANNELLI PORTA",
                            type: "filati",
                            slug: "filati_parti_rigide",
                            mesh: "pannello_porta_filati",
                            history: "FILATI PANNELLI PORTA",
                            historySlug: "filati_parti_rigide",
                            catHistorySlug: "filati",
                            onMaterial: "filati_parti_rigide",
                            offMaterial: "filati_parti_rigide_off",
                            materials: ['no_filati_parti_rigide', 'FR10', 'FR520', 'FR513', 'FR3', 'FR92', 'FR830', 'FR415', 'FR822', 'FR717', 'FR775', 'FR573', 'FR308', 'FR0010', 'FR323', 'FR406', 'FR407', 'FR932', 'FR0019', 'FR0017', 'FR617', 'FR614', 'FR0020'],
                        },
                    ],
                },
            ],
            historyArray: [
                'centrale_sedile',
                'centrale_design',
                'centrale_traforature',
                'esterno_sedile',
                'volante',
                'pannello_porta',
                'filati_sedile',
                'filati_design',
                'filati_volante',
                'filati_parti_rigide'
            ],
            materialHistoryArray: [
                'centrale_sedile',
                'esterno_sedile',
                'volante',
                'pannello_porta',
                'filati_sedile',
                'filati_volante',
                'filati_parti_rigide'
            ],
            catHistoryArray: [
                { name: "SEDILE", code: "sedile", mandatory : true  },
                { name: "VOLANTE", code: "volante", mandatory : false  },
                { name: "PANNELLO PORTA", code: "pannello_porta", mandatory : true  },
                { name: "FILATI", code: "filati", mandatory : false },
            ],
            catHistoryMap: {
                sedile: false,
                volante: false,
                pannello_porta: false,
                filati: false
            }
        },
    ]
}