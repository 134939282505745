import React from 'react'
// import CloseIcon from "@mui/icons-material/Close";

function Instructions({ onClose, ...props }) {
  return(
    <>
        <main>
          <section className="instructions">
            <div className="row g-5">
              <div className="col-md-6 border-end border-secondary">
                <h2 className="fw-bold">ISTRUZIONI</h2>
                <ul className="instruction-list p-0">
                  <li>
                    <span>1</span>
                    <div className="entry-content">
                      <p>Se stai leggendo queste istruzioni, significa che sei entrato come Utente Registrato e quindi riconosciuto.</p>
                    </div>
                  </li>
                  <li>
                    <span>2</span>
                    <div className="entry-content">
                      <p>Seleziona il tipo di veicolo desideri personalizzare, il Sistema offre 3 differtenti scelte:</p>
                      <ul className="list-disc">
                        <li>Segmetno A e B Family Car - che corrisponde ad auto due volumi, auto di piccole e medie dimesioni.</li>
                        <li>Segmento C e D Premium - Corrispondono a berline, familiari, monovolumi di medio grandi dimensioni.</li>
                        <li>Supercar, Prototipi ed Auto Evento</li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <span>3</span>
                    <div className="entry-content">
                      <p>
                        Il Sistema porta ad una schermata, dove al centro potrete trovare il modello generico selezonato, tutto in bianco (neutro). Sulla sinistra si trova il
                        pannello selezioni, dove fare le proprie scelte i tasti a ‘soffietto’ una volta cliccati offrono le varie possibilità previste. Sulla destra venogono elencate
                        la scelte, via via, fatte.
                      </p>
                    </div>
                  </li>
                  <li>
                    <span>4</span>
                    <div className="entry-content">
                      <p>
                        Al ternime della personalizzazioni delle sedute (sedili) in tutte le parti previste, il tasto ‘Continua’ si attiva e consente di proseguire, si accede quindi
                        ad una schermata finale dove il sistema richiede: quali ulteriori parti degli interni aggiungere alle sedute (es. Volante, Portiere, ecc.); e alcune
                        specifiche sul veicolo interessato.
                      </p>
                    </div>
                  </li>
                  <li>
                    <span>5</span>
                    <div className="entry-content">
                      <p>
                        Cliccando sul tasto ‘Genera Pdf’ il sisteam crea un documento in real-time dove si potranno anche visualizzare immagini ad alta risoluzione del proprio
                        progetto.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-sm-6">
                <div className="spacer-lg-6"></div>
                <div className="clearfix">
                  <img src="/images/chair.png" className="col-md-7 img-fluid float-sm-end chair-img" alt="..." />
                  <ul className="list-unstyled spacification-list">
                    <li>
                      <p>Pecifiche relative alla personalizzazione delle sedute o sedili.</p>
                      <ul className="list-number ps-3 mb-0">
                        <li>
                          Pannello Centrale Schienale<small>(esterni)</small>
                        </li>
                        <li>
                          Pannelli Laterali Schienale <small>(centrali)</small>
                        </li>
                        <li>
                          Pannello Centrale Seduta <small>(centrali)</small>
                        </li>
                        <li>
                          Pannelli Laterali Seduta <small>(esterni)</small>
                        </li>
                        <li>
                          Frontale Basso <small>(esterni)</small>
                        </li>
                        <li>
                          Laterali Bassi <small>(esterni)</small>
                        </li>
                        <li>
                          Retro Schienale <small>(esterni)</small>
                        </li>
                        <li>
                          Appoggiatesta <small>(esterni)</small>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <div className="note bg-400">
                        <p>
                          Se si hanno richieste specifiche che il Configuratore non prevede, effettuate la configurazione più simile a quanto desiderate e descrivete nel campo note
                          quanto desiderate in più nella configurazione effettua.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="spacer-lg-1 border-bottom border-secondary"></div>
                <div className="spacer-lg-1"></div>
                <div className="d-flex">
                  <div className="bg-success flex-grow-1"></div>
                  <div className="flex-grow-1 text-center">Hand Made Quality</div>
                  <div className="bg-danger flex-grow-1"></div>
                </div>
              </div>
            </div>
          </section>
        </main>
        <footer>
        <div className="social-media d-flex align-items-center">
            <a className="font-bold mb-0 text-white"  target="_blank" href="https://www.medicistyle.com/">medicistyle.atelier</a>
            <ul className="list-inline ml-xs-2 mb-0 d-flex">
            <li className="list-inline-item d-flex align-items-center">
              <a target="_blank" href="https://www.facebook.com/mediciinterniperauto" className="social-facebook" >
              </a>
            </li>
            <li className="list-inline-item d-flex align-items-center">
              <a target="_blank" className="social-instagram" href="https://www.instagram.com/medicistyleatelier/" ></a>
            </li>
            <li className="list-inline-item d-flex align-items-center">
              <a target="_blank" className="social-linkedin" href="https://www.linkedin.com/company/medici-style-srl/" ></a>
            </li>
          </ul>
        </div>
          <div className="entry-footer d-md-flex flex-grow-1 align-items-center">
            <p className="font-bold mb-md-0">info@medicistyle.it</p>
            <div className="btn-group ms-auto">
            <button className="btn btn-primary fs-sm-15 text-uppercase mx-1" onClick={onClose}>
              Indietro
            </button>
            </div>
          </div>
        </footer>

</>
  );
}

export default Instructions
