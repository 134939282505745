import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { config } from "../data";
import "../scss/main.scss";
import "../styles/developer.scss";
import "../styles/latest-developer.scss";
import Popup from "../components/Popup.js"

/* function Popup({ isOpen, onClose }) {
  if (!isOpen) return null;

  return (
    <div className="popup">
      <div className="popup-content">
        <span className="close" onClick={onClose}>&times;</span>
        <h2>!!! ATTENZIONE !!!</h2>
        <p>La versione per smartphone è attualmente in versione beta test.</p>
        <p>Visto la dimensione dei file trattati, non siamo in grado di garantire il corretto funzionamento su tutti i modelli di cellulare, per verificare il corretto funzionamento si consiglia di essere connessi ad una rete WiFi.</p>
        <p>Nonostante la tipologia di connessione, anche la qualità della stessa o il software dello smartphone poterebbero inficiare l'utilizzo del configuratore da smartphone.</p>
        <p>Se dovessi riscontrare problemi di navigazione dallo smartphone suggeriamo di utilizzare una postazione desktop (computer), tramite la quale potrai godere della migliore qualità dell'esperienza di simulazione.</p>
        <p>Per qualsiasi necessità non esitate a scrivere a: <a href="mailto:support@medicistyle.it">support@medicistyle.it</a></p>
      </div>
    </div>
  );
} */


function useOutsideAlerter(ref, callback) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback]);
}

export default function Series() {
  const wrapperRef = useRef(null);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isNavOpen, setNavOpen] = useState(false);
  const [isPopupOpen, setPopupOpen] = useState(true);

  const { lang, seriesId, productId } = useParams();


  // var langError = true;
  if (
    config.languages.find((L) => {
      return L === lang;
    }) == null
  ) {
    // throw new Error("language not valid : " + lang);
  }

  useOutsideAlerter(wrapperRef, () => {
    setDropdownOpen(false);
  });

  return (
    <>
      <main>
        <section className="introduction d-flex flex-column align-items-center justify-content-center">
          <div className="logo-main">
            <Link to={"/" + lang + "/"}>
              <img src="/images/logo-medici-atelier-dark.svg" alt="Medicistyle car interiors" className="img-fluid" />
            </Link>
          </div>

          <div className="spacer-lg-8"></div>
          <div className="entry-info text-center">
            <h1>INTERIORS CONFIGURATOR</h1>
          </div>
          <div className="spacer-lg-8"></div>
          <Link className="btn btn-primary text-uppercase fs-sm-20" to={`/${lang}/collections`} >
            Entra
          </Link>
        </section>
      </main>
      <div className="spacer-lg-1"></div>
      <footer>
        <div className="social-media d-flex align-items-center">
            <a className="font-bold mb-0 text-white"  target="_blank" href="https://www.medicistyle.com/">medicistyle.atelier</a>
            <ul className="list-inline ml-xs-2 mb-0 d-flex">
            <li className="list-inline-item d-flex align-items-center">
              <a target="_blank" href="https://www.facebook.com/mediciinterniperauto" className="social-facebook" >
              </a>
            </li>
            <li className="list-inline-item d-flex align-items-center">
              <a target="_blank" className="social-instagram" href="https://www.instagram.com/medicistyleatelier/" ></a>
            </li>
            <li className="list-inline-item d-flex align-items-center">
              <a target="_blank" className="social-linkedin" href="https://www.linkedin.com/company/medici-style-srl/" ></a>
            </li>
          </ul>
        </div>
        <div className="entry-footer d-md-flex flex-grow-1 align-items-center">
          <p className="font-bold mb-md-0">info@medicistyle.it</p>
          <div className="btn-group ms-auto">
            {/* <Link className="btn btn-primary fs-sm-15 text-uppercase mx-1" to={`/${lang}/collections`}>
              Istruzioni
            </Link> */}
          </div>
        </div>
      </footer>
      {isPopupOpen && <Popup isOpen={isPopupOpen} onClose={() => setPopupOpen(false)} />}
    </>
  );
}
