import { config } from "../data";
import { useParams } from "react-router-dom";
import checkMandatorySections from "../utils/checkMandatorySections.js"


export default function FinishConfiguration({catHistory}) {
    const { lang, seriesId, productId } = useParams();
    const catHistoryArray = config.series[seriesId].products[productId].catHistoryArray;
    const configMessage = checkMandatorySections(catHistoryArray,catHistory) ?
    "Hai configurato le seguenti opzioni:" : "Configura le sezioni obbligatorie prima di procedere!"


    return <div className="finish-configuration">
        <div className="entry-title text-center">
            <h2 className="mb-3">LA TUA CONFIGURAZIONE</h2>
            <p>{configMessage}</p>
        </div>
        <div className="spacer-lg-2"></div>
        <div className="entry-button">
            {catHistoryArray.map((catH, catHIndex) => {
                return <div
                    key={catHIndex}
                    
                    // className={`btn btn-outline-secondary text-uppercase ${catHistory[catH.code] ? "active" : ""}`}
                    
                    style={{ 
                        backgroundColor: catHistory[catH.code] ? "#0095db" : "white", 
                        borderColor: catHistory[catH.code] ? "#0095db" : "black", 
                        color: catHistory[catH.code] ? "white" : "black", 
                        padding: "0.7rem 4rem",
                        fontSize: "1.2rem",
                        borderRadius: "0rem",
                        // width: "14rem",
                        margin: "0.6rem",
                        display: "inline-block",
                        whiteSpace: "nowrap",
                        fontWeight: 400,
                        lineHeight: 1.5,
                        
                        textAlign: "center",
                        textDecoration: "none",
                        verticalAlign: "middle",
                        border: "1px solid black",
                    }}

                    
                    
                >
                    {catH.name}
                    { !catHistory[catH.code] && catH.mandatory &&
                    <img src={"/images/warning_icon.png"} alt="" style={{ marginLeft: "24px" ,height: "1rem" }}></img> }
                    
                </div>
            })}
        </div>
    </div>
}